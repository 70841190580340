import { useEffect, useState } from 'react';
import {Button, Col, Divider, Form, Input, message, Row, Select, Switch} from 'antd';
import Card from 'antd/es/card/Card';
import HeaderBreadcrumb from '../../../components/Breadcrumb';
import createPageTitle from '../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';
import {sendCustomNotification, sendNotification} from '../../../services/notificationService';
import {getAllCommodities} from '../../../services/commodityService';
import {CommodityRes} from '../../../core/dtos/commodityRes';
import {getAllSubscribers} from '../../../services/subscriberService';
import {AllSubscriberItem} from '../../../core/dtos/subscriberRes';
import {SubscriberStatus} from '../../../core/enums/SubscriberStatus';
import {NotificationGroupType} from '../../../core/enums/NotificationGroupType';
import {
  CheckOutlined, CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

export default function Notifications() {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [isSendAll, setIsSendAll] = useState<boolean>(false);
  const [subscribers, setSubscribers] = useState<AllSubscriberItem[]>([]);
  const [commodities, setCommodities] = useState<CommodityRes[]>([]);
  const statusValue = Form.useWatch('status', form);
  const commoditiesValue = Form.useWatch('commodities', form);

  useEffect(() => {
    Promise.all([
      getAllSubscribers(),
      getAllCommodities()
    ])
      .then(([subscribers, commodities]: [AllSubscriberItem[], CommodityRes[]]) => {
        setSubscribers(subscribers);
        setCommodities(commodities);
      });
  }, []);

  useEffect(() => {
    handleFilterRecipients();
  }, [statusValue, commoditiesValue]);

  const handleSubmit = () => {
    if (isSendAll) {
      sendNotification(form.getFieldsValue())
        .then(() => {
          messageApi.open({
            type: 'success',
            content: 'Notification successfully sent',
          });
        }
        ).catch(() => {
          messageApi.open({
            type: 'error',
            content: 'There is a problem',
          });
        });
    } else {
      sendCustomNotification({
        ...form.getFieldsValue(),
        status: undefined,
        commodities: undefined
      })
        .then((res) => {
          if (res === 'FAIL') {
            messageApi.open({
              type: 'warning',
              content: 'Some recipients couldn\'t receive the notification',
            });
          }
          messageApi.open({
            type: 'success',
            content: 'Notification successfully sent',
          });
        }
        ).catch(() => {
          messageApi.open({
            type: 'error',
            content: 'There is a problem',
          });
        });
    }
  };


  const handleFilterRecipients = () => {
    const filteredSubscribers: AllSubscriberItem[] = subscribers.filter(
      subscriber => statusValue?.includes(subscriber.status)
        || !!commoditiesValue?.find((com: string) => subscriber.commodities?.includes(com))
    );
    form.setFieldValue('recipients', filteredSubscribers.map(sub => sub._id));
  };

  return (
    <>
      <Helmet>
        <title>{createPageTitle('Notifications')}</title>
      </Helmet>
      {contextHolder}
      <HeaderBreadcrumb items={['Notifications']} />
      <Card title="Send Notification">
        <Form name='User' form={form} onFinish={handleSubmit} layout='vertical'>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Form.Item
                name='title'
                rules={[{ required: true, message: 'Please enter a title' }, { min: 3, message: 'Title must be longer than 2 characters' }]}
                label='Title'
              >
                <Input placeholder="Title" size='large' />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name='body'
                rules={[{ required: true, message: 'Please enter a body' }, { min: 3, message: 'Body must be longer than 2 characters' }]}
                label='Body'
              >
                <Input.TextArea placeholder="Body" size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} style={{display: 'flex', alignItems:'center', gap:'8px'}}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                id="send-all"
                defaultChecked={false}
                onChange={(e) => setIsSendAll(e)}
              />
              <label htmlFor='send-all' style={{marginLeft: '8px'}}>
                <b>Send All</b>
              </label>
            </Col>
            {!isSendAll && (
              <>
                <Col xs={12} style={{marginTop: '24px'}}>
                  <Form.Item
                    label='Status'
                    name="status"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      size='large'
                      style={{ width: '100%' }}
                      placeholder="Select the status"
                      fieldNames={{ value: '_id', label: 'name' }}
                      options={[
                        {
                          name: 'Paid',
                          _id: SubscriberStatus.PAID,
                          type: NotificationGroupType.STATUS
                        },
                        {
                          name: 'Free Trial',
                          _id: SubscriberStatus.FREE_TRIAL,
                          type: NotificationGroupType.STATUS
                        }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} style={{marginTop: '24px'}}>
                  <Form.Item
                    label='Commodities'
                    name="commodities"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      size='large'
                      style={{ width: '100%' }}
                      placeholder="Select the relevant commodities"
                      fieldNames={{ value: '_id', label: 'name' }}
                      options={
                        commodities?.sort()?.map((commodity) => ({
                          _id: commodity?._id,
                          name: commodity?.name,
                          type: NotificationGroupType.COMMODITY
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name='recipients'
                    label='Recipients'
                    rules={[{ required: true, message: 'Please select at least 1 recipient' }]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      size='large'
                      style={{ width: '100%' }}
                      placeholder="Select the relevant recipient(s)"
                      fieldNames={{ value: '_id' }}
                      filterOption={(input: string, option: any) =>
                        ((option?.username + ' ' + option?.email) ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={subscribers?.sort()?.map(subscriber => ({
                        ...subscriber,
                        label: subscriber.username + ' - ' + subscriber.email
                      }))}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col xs={24} style={{marginTop: !isSendAll ? '-24px' : ''}}>
              <Divider />
            </Col>
            <Col xs={24} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Button type="primary" shape="default" icon={<DeleteOutlined />} size="large" danger style={{width: '200px'}} onClick={() => {
                form.setFieldsValue({title: '', body: '', groups: undefined, recipients: undefined});
                // setSelectedGroups([]);
              }}>
                Clear All
              </Button>
              <Button type="primary" htmlType='submit' shape="default" icon={<PlusCircleOutlined />} size="large" style={{width: '200px'}}>
                Send Notification
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}
