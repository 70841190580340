function get(index:string): string|null {
  return localStorage.getItem(index);
}

function set(index:string, value: string): void {
  localStorage.setItem(index, value);
}

function remove(index:string): void {
  localStorage.removeItem(index);
}

export default {
  set,
  get,
  remove
};