import axios from 'axios';
import { ACCESS_TOKEN } from '../core/consts/tokens';
import storageService from './storageService';

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

service.interceptors.request.use(
  (config: any) => {
    config.headers['platform'] = 'browser';

    const token = storageService.get(ACCESS_TOKEN);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

service.interceptors.response.use(
  config => config,
  (error) => {
    if (typeof window !== 'undefined' && error.response.status === 401) {
      storageService.remove(ACCESS_TOKEN);
      window.location.replace('/sign-in');
    }
    return Promise.reject(error);
  }
);

export default service;
