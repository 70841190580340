import {IMailGeneratorDragAndDrop} from '../types/MailGeneratorDragAndDrop';



const mailGeneratorData: IMailGeneratorDragAndDrop[] = [
  {
    id: '1',
    title: 'South & Central America',
    isVisible: true,
    posts: [],
    countries: [79, 132, 149, 168, 57, 80, 150, 101, 162, 169, 95, 128, 138, 53, 58, 63, 102, 151, 74, 55]
  },
  {
    id: '2',
    title: 'APAC',
    isVisible: true,
    posts: [],
    countries: [14, 19, 22, 24, 25, 26, 29, 183, 190, 8, 9, 10, 12, 21, 30, 32, 59, 56, 61, 191, 6, 15, 20, 27, 175, 176, 178, 180, 188, 7, 13, 16, 18, 28, 107, 181, 187, 189, 185, 11, 17, 23, 31, 184, 186, 173, 174, 177, 182, 194, 193, 192]
  },
  {
    id: '3',
    title: 'NAFTA',
    isVisible: true,
    posts: [],
    countries: [89, 87, 117, 54, 198, 153]
  },
  {
    id: '4',
    title: 'World',
    isVisible: true,
    posts: [],
    countries: [52]
  },
  {
    id: '5',
    title: 'Europe',
    isVisible: true,
    posts: [],
    countries: [75, 122, 152, 154, 196, 76, 99, 105, 108, 110, 129, 172, 82, 96, 146, 157, 160, 36, 85, 93, 106, 109, 159, 163, 66, 67, 71, 72, 81, 97, 121, 139, 140, 84, 203, 147]
  },
  {
    id: '6',
    title: 'Eurasia',
    isVisible: true,
    posts: [],
    countries: [120, 143, 5, 33, 34, 65, 142, 2, 4, 118, 1, 3, 37, 64, 208, 133]
  },
  {
    id: '7',
    title: 'Africa & Middle East',
    isVisible: true,
    posts: [],
    countries: [92, 195, 98, 116, 123, 130, 144, 145, 42, 114, 134, 170, 199, 141, 148, 39, 46, 112, 115, 124, 125, 158, 45, 47, 50, 68, 69, 171, 156, 49, 77, 86, 111, 62, 70, 78, 88, 94, 100, 103, 104, 113, 127, 135, 136, 164, 35, 38, 43, 51, 40, 41, 44, 48, 60, 73, 126, 91, 202, 200]
  },
  {
    id: '8',
    title: 'Caribbean',
    isVisible: true,
    posts: [],
    countries: [119, 137, 131, 161, 155, 179, 197, 83, 90, 205, 201]
  },
  {
    id: '9',
    title: 'ECRUU News',
    isVisible: true,
    posts: [],
    countries: [204]
  }
];

export default mailGeneratorData;
