import '../css/mail-preview.scss';
import {Button, Card} from 'antd';
import {CopyOutlined, MailOutlined} from '@ant-design/icons';
import {MessageInstance} from 'antd/es/message/interface';

export default function MailPreview({isActive, mailTemplateResult, messageApi}: {isActive: boolean; mailTemplateResult: string; messageApi: MessageInstance;}) {
  const handleCopyClipboard = () => {
    navigator.clipboard.writeText(mailTemplateResult);

    messageApi.open({
      type: 'success',
      content: 'Mail template successfully copied'
    });
  };

  return (
    <Card
      title="Mail Template"
      extra={(
        <Button
          className="copy-btn"
          type="dashed"
          icon={<CopyOutlined />}
          size="large"
          onClick={handleCopyClipboard}>
          Copy
        </Button>
      )}
    >
      <div className={'mail-container' + (!isActive ? ' no-data' : '')}>
        {!isActive && (
          <div className="overlay"></div>
        )}

        <div dangerouslySetInnerHTML={{__html: mailTemplateResult}} />

        {!isActive && (
          <a href="#" className="create-template-btn">
            <Button
              icon={<MailOutlined />}
              type='primary'
              size="large">
                  Create Template
            </Button>
          </a>
        )}
      </div>
    </Card>
  );
}
