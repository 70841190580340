import service from './axiosRequest';
import {AxiosResponse} from 'axios';
import axios from 'axios';
import {BLOG_GENERATE_IMAGE_UPLOAD_LINK} from '../core/consts/endpoints';
import {GenerateImageUploadLinkRes} from '../core/dtos/generateImageUploadLinkRes';

export function generateImageUploadLink(body: { fileNameWithExtension: string; }) {
  return service.post(BLOG_GENERATE_IMAGE_UPLOAD_LINK, body)
    .then((res: AxiosResponse<GenerateImageUploadLinkRes>) => res.data);
}

export function uploadImage(path: string, body: File) {
  return axios.put(path, body)
    .then((res: AxiosResponse<void>) => res.data);
}
