export const LOGIN='auth/admin/login';
export const AUTH_CHECK='auth/admin/check';
export const LOGOUT='auth/admin/logout';
export const RESET_PASSWORD='auth/admin/reset-password';
export const UPDATE_PASSWORD='auth/admin/update-password';
export const DASHBOARD_LOGIN_STATICS='dashboard/login-statistics';
export const DASHBOARD_BADGE='dashboard/badge';
export const DASHBOARD_RANKING_ACTIVE_USERS='dashboard/ranking-active-users';
export const DASHBOARD_APP_USAGE='dashboard/app-usage';
export const BLOG='blog';
export const COMMENT='comment';
export const COMMENT_APPROVE='comment/approve';
export const POST='post';
export const EXPORT_POSTS='export-posts';
export const CATEGORY='category';
export const CATEGORY_ALL='category/all';
export const COUNTRY='country';
export const COUNTRY_ALL='country/all';
export const COMMODITIY='commodity';
export const COMMODITIY_ALL='commodity/all';
export const SOURCE='source';
export const SOURCE_ALL='source/all';
export const SUBSCRIBER='subscriber';
export const SUBSCRIBER_ALL='subscriber/all';
export const USER='user';
export const NOTIFICATION_ALL='notification/all';
export const NOTIFICATION_CUSTOM='notification/custom';
export const BLOG_GENERATE_IMAGE_UPLOAD_LINK='blog/generate-image-upload-link';
export const CONTACT='contact';
