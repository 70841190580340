import {Link} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import {HttpRes} from '../../../../core/dtos/httpRes';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import formatDate from '../../../../utils/formatDate';
import Loader from '../../../../components/Loader';
import Pagination from 'antd/lib/pagination';
import {Helmet} from 'react-helmet';
import createPageTitle from '../../../../utils/createPageTitle';
import {BlogRes} from '../../../../core/dtos/blogRes';
import {RequestFilter} from '../../../../core/types/RequestFilter';
import {deleteBlog, getBlog, getBlogs} from '../../../../services/blogService';
import {PostRequestFilter} from '../../../../core/types/PostRequestFilter';
import Search from 'antd/es/transfer/search';
import {debounce} from 'lodash';
import Meta from 'antd/es/card/Meta';
import {BlogDetailRes} from '../../../../core/dtos/blogDetailRes';
import {approveComment, deleteComment} from '../../../../services/commentService';
import {CommentStatus} from '../../../../core/enums/CommentStatus';
import {Button, Card, Col, message, Modal, Popconfirm, Row, Table, Tooltip} from 'antd';
import {
  CheckCircleOutlined,
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  CheckCircleFilled,
  EyeOutlined
} from '@ant-design/icons';
import {Comment} from '../../../../core/types/Comment';
import {DashboardBadgeRes} from '../../../../core/dtos/dashboardBadgeRes';
import {useApp} from '../../../../context/siteContext';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Created By',
    key: 'createdBy',
    dataIndex: 'createdBy',
  },
  {
    title: 'Created At',
    key: 'createdAt',
    dataIndex: 'createdAt',
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
  }
];

function Blogs() {
  const [messageApi, contextHolder] = message.useMessage();
  const { dashboardBadge }: { dashboardBadge?: DashboardBadgeRes } = useApp();
  const [blogs, setBlogs] = useState<HttpRes<BlogRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });
  const [filter, setFilter] = useState<RequestFilter>({
    page: 1,
    limit: 20,
    sortBy: 'orderIndex',
    orderBy: 'desc'
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCommentsPopupOpen, setIsCommentsPopupOpen] = useState<boolean>(false);
  const [commentDetails, setCommentDetails] = useState<Comment|void>();
  const [editBlogCommentValues, setEditBlogCommentValues] = useState<BlogDetailRes>({
    _id: '',
    createdBy: '',
    content: '',
    createdAt: '',
    title: '',
    images: [],
    comments: [],
    category: '',
    commentCount: 0,
    orderIndex: 0,
    permalink: '',
    isEnabled: false,
    excerpt: '',
    summary: ''
  });
  const [openedCommentInfos, setOpenedCommentInfos] = useState<string[]>([]);

  const mapPosts = () => blogs.items.map(blog => (
    {
      key: blog._id,
      title: <b>{blog.title}</b>,
      createdBy: <span>{blog.createdBy}</span>,
      createdAt: <span>{formatDate(blog.createdAt)}</span>,
      actions: (
        <Row className="ant-employed table-actions" gutter={[8, 0]}>
          <Col>
            <Tooltip title="Comments">
              <Button
                type="primary"
                shape="circle"
                onClick={() => handleEditComments(blog._id)}
                style={{ background: '#1677ff' }}
                icon={<CommentOutlined />} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Edit">
              <Link to={blog._id}>
                <Button 
                  type="primary" 
                  shape="circle" 
                  icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              placement="bottomRight"
              title='Delete the Blog'
              description='Are you sure to delete this blog?'
              onConfirm={() => {
                deleteBlog(blog._id)
                  .then(() => {
                    handleGetBlogs();
                  });
              }}
              okText="Delete"
              cancelText="Cancel"
            >
              <Tooltip title="Delete Blog">
                <Button 
                  danger
                  type="primary" 
                  shape="circle" 
                  icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    }
  ));

  const handleGetBlogs = () => {
    getBlogs(filter)
      .then((body: HttpRes<BlogRes>) => setBlogs(body))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleGetBlogs();
  }, [filter]);

  const changeFilter = (changedFilter: Partial<PostRequestFilter>) => {
    setFilter((filter) => ({
      ...filter,
      ...changedFilter
    }));
  };

  const handleSearchWithDebounce = useMemo(() => {
    return debounce(
      (e) => changeFilter({ searchText: e.target.value }),
      500
    );
  }, []);

  const handleEditComments = (id: string) => {
    getBlog(id)
      .then(body => {
        setEditBlogCommentValues(body);
      });

    setIsCommentsPopupOpen(true);
  };

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle('Blogs')}</title>
      </Helmet>
      {contextHolder}
      <div className='breadcrumb-wrapper'>
        <HeaderBreadcrumb items={['Blogs']} />
        <Row gutter={[12, 0]}>
          <Col>
            <Link to='/blog-comments'>
              <Button
                type="primary"
                shape="default"
                size="large"
                style={{ background: '#1677ff' }}
                icon={<CommentOutlined />}>
                Approve Comments ({dashboardBadge?.unapprovedComments})
              </Button>
            </Link>
          </Col>
          <Col>
            <Link to='create'>
              <Button 
                type="primary" 
                shape="default" 
                size="large" 
                style={{width: '120px'}}
                icon={<PlusCircleOutlined />}>
            Create
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="List of Blogs"
              extra={
                <Row className='table-filter'>
                  <Col xs={12} style={{marginRight: '10px'}}>
                    <Search
                      placeholder="Search..."
                      onChange={handleSearchWithDebounce} />
                  </Col>
                </Row>
              }>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={mapPosts()}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Card style={{marginTop: '16px', justifyContent: 'center', display:'flex'}}>
        <Pagination total={blogs.totalItemCount}
          current={filter.page} 
          onChange={(e) => changeFilter({ page: e })}
          pageSize={filter.limit} 
          showTotal={(total) => `Total ${total} items`} 
          onShowSizeChange={(e, selectedNumber) => changeFilter({ limit: selectedNumber })}
          defaultPageSize={20}
          showSizeChanger />
      </Card>

      <Modal
        centered
        title={`Comments: ${editBlogCommentValues.title}`}
        open={isCommentsPopupOpen}
        onOk={() => setIsCommentsPopupOpen(false)}
        onCancel={() => setIsCommentsPopupOpen(false)}
        width={800}
      >
        <div>
          {editBlogCommentValues.comments.length ? (
            <Row gutter={[16, 16]} style={{marginTop: '28px', marginBottom: '28px'}}>
              {editBlogCommentValues.comments.map((comment, key) => (
                <Col key={key} xs={24} md={12}>
                  <Card
                    style={{ width: '100%' }}
                    actions={[
                    // eslint-disable-next-line react/jsx-key
                      comment.status !== CommentStatus.APPROVED ? (
                        <Popconfirm
                          placement="bottomRight"
                          title='Approve the Comment'
                          description='Are you sure to approve this comment?'
                          onConfirm={() => {
                            approveComment(comment._id)
                              .then(() => {
                                messageApi.open({
                                  type: 'success',
                                  content: 'Comment successfully approved',
                                });
                                handleEditComments(editBlogCommentValues._id);
                              });
                          }}
                          okText="Approve"
                          cancelText="Cancel"
                        >
                          <Tooltip title="Approve Comment">
                            <CheckCircleOutlined key="check" style={{fontSize: '16px'}} />
                          </Tooltip>
                        </Popconfirm>
                      ) : (
                        <CheckCircleFilled key="check" style={{fontSize: '16px', color: 'limegreen'}} />
                      ),
                      // eslint-disable-next-line react/jsx-key
                      <Tooltip title="Show More">
                        <EyeOutlined key="ellipsis" style={{ fontSize: '16px' }} onClick={() => setCommentDetails(comment)} />
                      </Tooltip>,
                      // eslint-disable-next-line react/jsx-key
                      <Popconfirm
                        placement="bottomRight"
                        title='Delete the Comment'
                        description='Are you sure to delete this comment?'
                        onConfirm={() => {
                          deleteComment(comment._id)
                            .then(() => {
                              handleEditComments(editBlogCommentValues._id);
                            });
                        }}
                        okText="Delete"
                        cancelText="Cancel"
                      >
                        <Tooltip title="Delete Comment">
                          <DeleteOutlined key="ellipsis" style={{ color: 'orangered', fontSize: '16px' }} />
                        </Tooltip>
                      </Popconfirm>,
                    ]}
                  >
                    <Meta
                      title={[
                        comment.name,
                        // eslint-disable-next-line react/jsx-key
                        <small className="ant-card-meta-description" style={{marginLeft: '8px', fontWeight: '400'}}>{formatDate(comment.createdAt)}</small>
                      ]}
                      description={comment.content.slice(0, 90) + (comment.content.length>=100 ? '...' : '')}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <p>There is no comment for this blog</p>
          )}
        </div>
      </Modal>

      <Modal
        centered
        title={`${commentDetails?.name}'s Comment`}
        open={!!commentDetails}
        onOk={() => setCommentDetails()}
        onCancel={() => setCommentDetails()}
        width={800}
      >
        <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
          <b>Email:</b>
          <p style={{margin: '4px'}}>{commentDetails?.email}</p>
        </div>
        {commentDetails?.website && (
          <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
            <b>Website:</b>
            <p style={{margin: '4px'}}>{commentDetails.website}</p>
          </div>
        )}
        <p>
          {commentDetails?.content}
        </p>
      </Modal>
    </>
  );
}

export default Blogs;
