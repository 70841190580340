import React, {useEffect, useState} from 'react';
import Chart, { Props as ApexProps } from 'react-apexcharts';
import generateChartData from '../../../../../utils/generateChartData';
import {ChartData} from '../../../../../core/types/ChartData';

function ApexChart({chartData}: {chartData: ChartData[]}) {
  const [chartState, setChartState] = useState<ApexProps>(generateChartData([]));

  useEffect(() => {
    if (!chartData) return;
    setChartState(generateChartData(chartData));
  }, [chartData]);

  return (
    <Chart
      options={chartState.options}
      series={chartState.series}
      type="bar"
      height={380}
    />
  );
}

export default ApexChart;
