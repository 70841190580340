import {Link} from 'react-router-dom';
import {Button, Card, Col, Form, Popconfirm, Row, Table, Tag, Tooltip,} from 'antd';
import Search from 'antd/es/transfer/search';
import {useEffect, useMemo, useState} from 'react';
import {debounce} from 'lodash';
import {HttpRes} from '../../../../core/dtos/httpRes';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import formatDate from '../../../../utils/formatDate';
import Loader from '../../../../components/Loader';
import Pagination from 'antd/lib/pagination';
import {SubscriberRes} from '../../../../core/dtos/subscriberRes';
import {deleteSubscriber, getSubscribers} from '../../../../services/subscriberService';
import {SubscriberStatus} from '../../../../core/enums/SubscriberStatus';
import {SubscriberRequestFilter} from '../../../../core/types/SubscriberRequestFilter';
import {Helmet} from 'react-helmet';
import createPageTitle from '../../../../utils/createPageTitle';
import ModalSubscribersFilter from './components/ModalFilter';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

const generateFilterParams = (filter: SubscriberRequestFilter): SubscriberRequestFilter => ({
  limit: filter.limit || undefined,
  page: filter.page || undefined,
  name: filter.name || undefined,
  email: filter.email || undefined,
  commodities: filter.commodities || undefined,
  status: filter.status || undefined,
  username: filter.username || undefined,
  fromCreatedAt: String(filter.createdAt ? filter.createdAt[0].$d.toISOString() : '') || undefined,
  toCreatedAt: String(filter.createdAt ? filter.createdAt[1].$d.toISOString() : '') || undefined,
  fromValidTill: String(filter.validTill ? filter.validTill[0].$d.toISOString() : '') || undefined,
  toValidTill: String(filter.validTill ? filter.validTill[1].$d.toISOString() : '') || undefined,
  isBlocked: filter?.isBlocked
});

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Commodities',
    dataIndex: 'commodities',
    key: 'commodities',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Valid Till',
    dataIndex: 'validTill',
    key: 'validTill',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];

function Subscribers() {
  const [form] = Form.useForm();
  const [subscribers, setSubscribes] = useState<HttpRes<SubscriberRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<SubscriberRequestFilter>({
    page: 1,
    limit: 20,
  });
  const [isSearchModalOpened, setIsSearchModalOpened] = useState<boolean>(false);

  const mapSubscribers = (subscribers: SubscriberRes[]) => subscribers.map(subscriber => (
    {
      key: subscriber._id,
      name: (
        <Link to={subscriber._id}>{subscriber.name}</Link>
      ),
      username: (
        <span>{subscriber.username}</span>
      ),
      email: (
        <span>{subscriber.email}</span>
      ),
      commodities: (
        <span>{subscriber.commodities[0]?.name}</span>
      ),
      status: (
        <>
          {subscriber.isBlocked ? (
            <Tag color="red">Blocked</Tag>
          ) : (
            <Tag color={subscriber.status === SubscriberStatus.FREE_TRIAL ? 'default' : 'green'}>{subscriber.status}</Tag>
          )}
          {new Date(subscriber.validTill) < new Date() && (
            <Tag color="orange">Expired</Tag>
          )}
        </>
      ),
      validTill: (
        <span>{formatDate(subscriber.validTill)}</span>
      ),
      createdAt: (
        <span>{formatDate(subscriber.createdAt)}</span>
      ),
      actions: (
        <Row className="ant-employed table-actions" gutter={[8, 0]}>
          <Col>
            <Tooltip title="Edit">
              <Link to={subscriber._id}>
                <Button 
                  type="primary" 
                  shape="circle" 
                  icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              placement="bottomRight"
              title='Delete the Subscriber'
              description='Are you sure to delete this subscriber?'
              onConfirm={() => {
                deleteSubscriber(subscriber._id)
                  .then(() => {
                    handleGetSubscribers();
                  });
              }}
              okText="Delete"
              cancelText="Cancel"
            >
              <Tooltip title="Delete Subscriber">
                <Button 
                  danger
                  type="primary" 
                  shape="circle" 
                  icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    }
  ));

  const handleGetSubscribers = () => {
    const filterParams: SubscriberRequestFilter = generateFilterParams(filter);

    getSubscribers(filterParams)
      .then((body: HttpRes<SubscriberRes>) => setSubscribes(body))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleGetSubscribers();
  }, [filter]);

  const changeFilter = (changedFilter: Partial<SubscriberRequestFilter>) => {
    setFilter((filter) => ({
      ...filter, 
      ...changedFilter
    }));
  };

  const handleSearchWithDebounce = useMemo(() => {
    return debounce(
      (e) => changeFilter({ username: e.target.value }),
      500
    );
  }, []);

  const refreshFilter = () => {
    form.resetFields();
    changeFilter({
      page: 1,
      name: undefined,
      email: undefined,
      commodities: undefined,
      status: undefined,
      username: undefined,
      createdAt: undefined,
      validTill: undefined,
      isBlocked: undefined
    });
  };

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle('Subscribers')}</title>
      </Helmet>
      <div className='breadcrumb-wrapper'>
        <HeaderBreadcrumb items={['Subscribers']} />
        <Row gutter={[12, 0]}>
          <Col>
            <Button
              type="default"
              shape="default"
              size="large"
              onClick={() => setIsSearchModalOpened(true)}
              icon={<FilterOutlined />}>
              Advanced Filter
            </Button>
            {filter && (
              <Popconfirm
                placement="bottomRight"
                title='Refresh Filtering'
                description='Are you sure to refresh filtering?'
                onConfirm={refreshFilter}
                okText="Refresh"
                cancelText="Cancel"
              >
                <Tooltip title="Refresh filter">
                  <Button type="primary" icon={<CloseCircleOutlined />} className="reset-filter-button" />
                </Tooltip>
              </Popconfirm>
            )}
          </Col>
          <Col>
            <Link to='create'>
              <Button 
                type="primary" 
                shape="default" 
                size="large" 
                style={{width: '120px'}}
                icon={<PlusCircleOutlined />}>
            Create
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="List of Subscribers"
              extra={
                <Row className='table-filter'>
                  <Col xs={12} style={{marginRight: '10px'}}>
                    <Search 
                      placeholder="Search by username"
                      onChange={handleSearchWithDebounce} />
                  </Col>
                </Row>
              }>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={mapSubscribers(subscribers.items)}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <ModalSubscribersFilter
        isOpen={isSearchModalOpened}
        setIsOpen={setIsSearchModalOpened}
        changeFilter={changeFilter}
        form={form} />

      <Card style={{marginTop: '16px', justifyContent: 'center', display:'flex'}}>
        <Pagination total={subscribers.totalItemCount} 
          current={filter.page} 
          onChange={(e) => changeFilter({ page: e })} 
          pageSize={filter.limit} 
          showTotal={(total) => `Total ${total} items`} 
          onShowSizeChange={(e, selectedNumber) => {changeFilter({ limit: selectedNumber});}} 
          defaultPageSize={20}
          showSizeChanger />
      </Card>
    </>
  );
}

export default Subscribers;
