import { AxiosResponse } from 'axios';
import {EXPORT_POSTS, POST} from '../core/consts/endpoints';
import { HttpRes } from '../core/dtos/httpRes';
import { PostDetailRes } from '../core/dtos/postDetailRes';
import { PostReq } from '../core/dtos/postReq';
import { PostRes } from '../core/dtos/postRes';
import { PostRequestFilter } from '../core/types/PostRequestFilter';
import {ExportPostsRequestFilter} from '../core/types/ExportPostsRequestFilter';
import service from './axiosRequest';

export function getPosts(params: PostRequestFilter): Promise<HttpRes<PostRes>> {
  return service.get<HttpRes<PostRes>>(POST, { params })
    .then((res: AxiosResponse<HttpRes<PostRes>>) => res.data);
}

export function getExportPosts(params: ExportPostsRequestFilter): Promise<string> {
  return service.get<string>(EXPORT_POSTS, { params })
    .then((res: AxiosResponse<string>) => res.data);
}

export function getPost(id: string): Promise<PostDetailRes> {
  return service.get<PostDetailRes>(`${POST}/${id}`)
    .then((res: AxiosResponse<PostDetailRes>) => res.data);
}

export function createPost(body: PostReq) {
  return service.post(POST, body)
    .then((res: AxiosResponse<PostReq>) => res.data);
}

export function updatePost(body: PostReq) {
  return service.patch(POST, body)
    .then((res: AxiosResponse<PostReq>) => res.data);
}

export function deletePost(id: string) {
  return service.delete(`${POST}/${id}`);
}
