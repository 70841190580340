import { Breadcrumb } from 'antd';

export default function HeaderBreadcrumb({items}: any) {
  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      {items.map((item: string, key: number) => (
        <Breadcrumb.Item key={key}>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
