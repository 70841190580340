import {Dispatch, SetStateAction} from 'react';
import formatDate from '../../../../../utils/formatDate';
import {LinkOutlined, MailFilled, StarFilled} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {Modal} from 'antd';
import {CommodityRes} from '../../../../../core/dtos/commodityRes';
import {CountryRes} from '../../../../../core/dtos/countryRes';
import {SourceRes} from '../../../../../core/dtos/sourceRes';

export default function ModalPostPreview(
  {
    isOpen,
    setIsOpen,
    postData,
    commodities,
    countries,
    sources
  }:
    {
      isOpen: boolean,
        setIsOpen: Dispatch<SetStateAction<boolean>>,
        postData: any,
        commodities: CommodityRes[],
        countries: CountryRes[],
        sources: SourceRes[]
    }
) {

  const findItemInArray = (array: any[], itemId: string) => {
    return array.find((item: { _id: string; }) => item._id === itemId);
  };

  return (
    <Modal
      centered
      title="Post Content Preview"
      open={isOpen}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      width={1170}
      style={{marginTop: '28px', marginBottom: '28px'}}
    >
      <div className="post-preview-main">
        <div className="posts">
          <div id="post_list" className="post_list">
            <div className="post">
              <div className="post_left">
                <div className="post_title_row">
                  <div className="post_title">{postData?.headline}</div>
                </div>
                <div className="post_tags_row">
                  <div className="post_tags">
                    <div className="post_expire">{postData?.publishedAt ? formatDate(postData.publishedAt) : ''}</div>
                  </div>
                  <div className="post_tags">
                    <ul>
                      {postData?.commodities?.map((commodity: any, key: number) => (
                        <li key={key} style={{background: findItemInArray(commodities, commodity)?.colorCode || 'yellow'}}>
                          {commodity?.name || findItemInArray(commodities, commodity)?.name}
                        </li>
                      ))}
                      {postData?.countries?.map((country: any, key: number) => (
                        <li key={key} className="tag_country">
                          {country?.name || findItemInArray(countries, country)?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="post_source_link">
                    <div>
                      <LinkOutlined />
                      {postData.sources?.map((source: SourceRes, key: number) => (
                        <Link key={key} to="">
                          <span>{source?.name || findItemInArray(sources, source?._id)?.name}</span>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="post_data">
                  <div className="post_text">
                    <p style={{fontWeight: '400'}}>
                      <span dangerouslySetInnerHTML={{ __html: postData?.body }} />
                    </p>
                  </div>
                </div>
                <div className="clear"></div>
              </div>
              <div className="post_right">
                <div className="post_bookmark">
                  <div className="post_fav">
                    <StarFilled size={14} />
                    <span>Bookmark</span>
                  </div>
                </div>
                <div className="post_email">
                  <button>
                    <MailFilled size={14} />
                    <span>Share</span>
                    <span className="input">
                      <input type="text" placeholder="Email to share with" />
                      <input type="submit" value="Share" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

