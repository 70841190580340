import { Layout } from 'antd';
import {Outlet, useNavigate} from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import {useEffect, useState} from 'react';
import {LOGIN} from '../../../core/consts/routes';
import storageService from '../../../services/storageService';
import {ACCESS_TOKEN} from '../../../core/consts/tokens';
import {getUserInfo} from '../../../services/authService';
import Loader from '../../../components/Loader';

const { Content } = Layout;

export default function MainLayout() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const redirectToLogin = () => {
    setIsLoading(false);
    navigate(`/${LOGIN}`);
  };

  useEffect(() => {
    if (!storageService.get(ACCESS_TOKEN)) {
      redirectToLogin();
      return;
    }

    getUserInfo()
      .catch(() => redirectToLogin())
      .finally(() => setIsLoading(false));

  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout className="site-layout">
        <Content style={{ margin: '0 16px' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
