const formats = [
  'font',
  'size',
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'align',
  'color', 'background',
  'image',
  'link',
  'code-block',
  'blockquote'
];
const modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ['clean'],
    ['link', 'image'],
    ['blockquote', 'code-block'],
  ]
};

export default {
  formats,
  modules
};
