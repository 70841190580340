import service from './axiosRequest';
import {COMMENT, COMMENT_APPROVE} from '../core/consts/endpoints';
import {HttpRes} from '../core/dtos/httpRes';
import {AxiosResponse} from 'axios';
import {CommentRes} from '../core/dtos/commentRes';
import {CommentRequestFilter} from '../core/types/CommentRequestFilter';

export function getComments(params: CommentRequestFilter): Promise<HttpRes<CommentRes>> {
  return service.get<HttpRes<CommentRes>>(COMMENT, { params })
    .then((res: AxiosResponse<HttpRes<CommentRes>>) => res.data);
}

export function deleteComment(id: string) {
  return service.delete(`${COMMENT}/${id}`);
}

export function approveComment(id: string) {
  return service.patch(`${COMMENT_APPROVE}/${id}`);
}
