import {Link} from 'react-router-dom';
import Search from 'antd/es/transfer/search';
import {useEffect, useMemo, useState} from 'react';
import {debounce} from 'lodash';
import {PostRequestFilter} from '../../../../core/types/PostRequestFilter';
import {PostStatus} from '../../../../core/enums/PostStatus';
import {PostRes} from '../../../../core/dtos/postRes';
import {deletePost, getExportPosts, getPost, getPosts} from '../../../../services/postService';
import {HttpRes} from '../../../../core/dtos/httpRes';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import formatDate from '../../../../utils/formatDate';
import Loader from '../../../../components/Loader';
import Pagination from 'antd/lib/pagination';
import {getAllSources} from '../../../../services/sourceService';
import {getAllCategories} from '../../../../services/categoryService';
import {getAllCountries} from '../../../../services/countryService';
import {getAllCommodities} from '../../../../services/commodityService';
import {SourceRes} from '../../../../core/dtos/sourceRes';
import {CategoryRes} from '../../../../core/dtos/categoryRes';
import {CountryRes} from '../../../../core/dtos/countryRes';
import {CommodityRes} from '../../../../core/dtos/commodityRes';
import {Helmet} from 'react-helmet';
import createPageTitle from '../../../../utils/createPageTitle';
import {PostDetailRes} from '../../../../core/dtos/postDetailRes';
import ModalFilter from './components/ModalFilter';
import ModalPostPreview from './components/ModalPreview';
import {
  Button,
  Card,
  Col,
  Form,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Table,
  Tag,
  Tooltip
} from 'antd';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  PlusCircleOutlined,
  FileExcelOutlined
} from '@ant-design/icons';
import {ExportPostsRequestFilter} from '../../../../core/types/ExportPostsRequestFilter';

enum ExportPostLoadingStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  DISABLED = 'DISABLED',
}

const generateFilterParams = (filter: PostRequestFilter): PostRequestFilter => ({
  status: filter.status === PostStatus.ALL ? undefined : filter.status,
  searchText: filter.searchText || undefined,
  limit: filter.limit || undefined,
  page: filter.page || undefined,
  sources: filter.sources || undefined,
  categories: filter.categories || undefined,
  countries: filter.countries || undefined,
  commodities: filter.commodities || undefined,
  fromPublishedAt: String(filter.publishedAt ? filter.publishedAt[0].$d.toISOString() : '') || undefined,
  toPublishedAt: String(filter.publishedAt ? filter.publishedAt[1].$d.toISOString() : '') || undefined,
});

const columns = [
  {
    title: 'Published At',
    key: 'publishedAt',
    dataIndex: 'publishedAt',
  },
  {
    title: 'Headline',
    dataIndex: 'headline',
    key: 'headline',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: 'Commodities',
    key: 'commodities',
    dataIndex: 'commodities',
  },
  {
    title: 'Countries',
    key: 'countries',
    dataIndex: 'countries',
  },
  {
    title: 'Sources',
    dataIndex: 'sources',
    key: 'sources',
  },
  {
    title: 'Author',
    key: 'author',
    dataIndex: 'author',
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
  }
];

function Posts() {
  const [posts, setPosts] = useState<HttpRes<PostRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [exportPostsLoadingStatus, setExportPostsLoadingStatus] = useState<ExportPostLoadingStatus>(ExportPostLoadingStatus.IDLE);
  const [filter, setFilter] = useState<PostRequestFilter>({
    status: PostStatus.ALL, 
    page: 1,
    limit: 20,
    fromPublishedAt: '',
    toPublishedAt: '',
  });
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [sources, setSources] = useState<SourceRes[]>([]);
  const [categories, setCategories] = useState<CategoryRes[]>([]);
  const [countries, setCountries] = useState<CountryRes[]>([]);
  const [commodities, setCommodities] = useState<CommodityRes[]>([]);
  const [isPostPreviewModalOpen, setIsPostPreviewModalOpen] = useState<boolean>(false);
  const [postPreviewModalInfo, setPostPreviewModalInfo] = useState<Omit<PostDetailRes, 'commodities'> & { commodities: string[] }>({
    _id: '',
    sources: [],
    author: {
      _id: '',
      name: ''
    },
    body: '',
    createdAt: '',
    publishedAt: '',
    categories: [],
    commodities: [],
    countries: [],
    status: PostStatus.DRAFT,
    headline: '',
    story: ''
  });

  const handleGetPostPreview = (postId: string) => {
    getPost(postId)
      .then((body) => {
        setPostPreviewModalInfo({
          ...body,
          commodities: body.commodities.map(({
            _id
          }) => _id)
        });
        setIsPostPreviewModalOpen(true);
      });
  };

  const mapPosts = (posts: PostRes[]) => posts.map(post => (
    {
      key: post._id,
      countries: (
        <Space size={[0, 8]} wrap>
          {post.countries.map((country, key: number) => (
            <Tag key={key} color="lightgray">{country.name}</Tag>
          ))}
        </Space>
      ),
      sources: (
        <Space size={[0, 8]} wrap>
          {post.sources.map((source, key: number) => (
            <Tag key={key} color="yellow">{source.name}</Tag>
          ))}
        </Space>
      ),
      commodities: (
        <Space size={[0, 8]} wrap>
          {post.commodities.map((commodity, key: number) => (
            <Tag key={key} color={commodity.colorCode}>{commodity.name}</Tag>
          ))}
        </Space>
      ),
      headline: <b>{post.headline}</b>,
      author: <span>{post.author?.name}</span>,
      publishedAt: <span>{post?.publishedAt ? formatDate(post.publishedAt) : ''}</span>,
      status: <Tag color={post.status === PostStatus.DRAFT ? 'default' : 'green'}>{post.status}</Tag>,
      actions: (
        <Row className="ant-employed table-actions" gutter={[8, 0]}>
          <Col>
            <Tooltip title="Post Preview">
              <Button
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                style={{ background: '#1677ff' }}
                onClick={() => handleGetPostPreview(post._id)} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Edit">
              <Link to={post._id}>
                <Button 
                  type="primary" 
                  shape="circle" 
                  icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              placement="bottomRight"
              title='Delete the Post'
              description='Are you sure to delete this post?'
              onConfirm={() => {
                deletePost(post._id)
                  .then(() => {
                    handleGetPosts();
                  });
              }}
              okText="Delete"
              cancelText="Cancel"
            >
              <Tooltip title="Delete Post">
                <Button 
                  danger
                  type="primary" 
                  shape="circle" 
                  icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    }
  ));

  const handleGetPosts = () => {
    const filterParams: PostRequestFilter = generateFilterParams(filter);

    getPosts(filterParams)
      .then((body: HttpRes<PostRes>) => setPosts(body))
      .finally(() => setIsLoading(false));
  };

  const handleGetExportPostsLink = () => {
    setExportPostsLoadingStatus(ExportPostLoadingStatus.LOADING);

    const filterParams: ExportPostsRequestFilter = generateFilterParams({
      sources: filter.sources,
      categories: filter.categories,
      countries: filter.countries,
      commodities: filter.commodities,
      status: filter.status,
      publishedAt: filter.publishedAt,
      searchText: filter.searchText,
    });

    getExportPosts(filterParams)
      .then((link: string) => window.location.href = link)
      .finally(() => {
        setExportPostsLoadingStatus(ExportPostLoadingStatus.DISABLED);

        setTimeout(() => {
          setExportPostsLoadingStatus(ExportPostLoadingStatus.IDLE);
        }, 5000);
      });
  };

  useEffect(() => {
    Promise.all([
      getAllSources(),
      getAllCategories(),
      getAllCountries(),
      getAllCommodities(),
    ])
      .then(([sources, categories, countries, commodities]: [SourceRes[], CategoryRes[], CountryRes[], CommodityRes[]]) => {
        setSources(sources);
        setCategories(categories);
        setCountries(countries);
        setCommodities(commodities);
      });
  }, []);

  useEffect(() => {
    handleGetPosts();
  }, [filter]);

  const changeFilter = (changedFilter: Partial<PostRequestFilter>) => {
    setFilter((filter) => ({
      ...filter, 
      ...changedFilter
    }));
  };

  const handleSearchWithDebounce = useMemo(() => {
    return debounce(
      (e) => changeFilter({ searchText: e.target.value }),
      500
    );
  }, []);

  const refreshFilter = () => {
    form.resetFields();
    changeFilter({
      status: PostStatus.ALL,
      searchText: undefined,
      page: 1,
      sources: undefined,
      categories: undefined,
      countries: undefined,
      commodities: undefined,
      publishedAt: undefined,
    });
  };

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle('Posts')}</title>
        <meta/>
      </Helmet>
      <div className='breadcrumb-wrapper'>
        <HeaderBreadcrumb items={['Posts']} />
        <Row gutter={[12, 0]}>
          <Col>
            <Button
              type="default"
              shape="default"
              size="large"
              style={{background: '#63c21b', color: '#fff', borderColor: '#63c21b'}}
              onClick={() => handleGetExportPostsLink()}
              loading={exportPostsLoadingStatus === ExportPostLoadingStatus.LOADING}
              disabled={exportPostsLoadingStatus === ExportPostLoadingStatus.DISABLED}
              icon={<FileExcelOutlined />}>
              Download as XLSX
            </Button>
          </Col>
          <Col>
            <Button 
              type="default" 
              shape="default" 
              size="large"
              onClick={() => setIsFilterModalOpen(true)}
              icon={<FilterOutlined />}>
                Advanced Filter
            </Button>
            {filter && (
              <Popconfirm
                placement="bottomRight"
                title='Refresh Filtering'
                description='Are you sure to refresh filtering?'
                onConfirm={refreshFilter}
                okText="Refresh"
                cancelText="Cancel"
              >
                <Tooltip title="Refresh filter">
                  <Button type="primary" icon={<CloseCircleOutlined />} className="reset-filter-button" />
                </Tooltip>
              </Popconfirm>
            )}
          </Col>
          <Col>
            <Link to='create'>
              <Button 
                type="primary" 
                shape="default" 
                size="large" 
                style={{width: '120px'}}
                icon={<PlusCircleOutlined />}>
            Create
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="List of Posts"
              extra={
                <Row className='table-filter'>
                  <Col xs={12} style={{marginRight: '10px'}}>
                    <Search 
                      placeholder="Search in body or headline" 
                      onChange={handleSearchWithDebounce} />
                  </Col>
                  <Col xs={9}>
                    <Radio.Group 
                      value={filter.status} 
                      onChange={(e: RadioChangeEvent) => changeFilter({ status: e.target.value})}>
                      <Radio.Button value={PostStatus.ALL}>All</Radio.Button>
                      <Radio.Button value={PostStatus.PUBLISHED}>Published</Radio.Button>
                      <Radio.Button value={PostStatus.DRAFT}>Drafts</Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              }>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={mapPosts(posts.items)}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <Card style={{marginTop: '16px', justifyContent: 'center', display:'flex'}}>
        <Pagination total={posts.totalItemCount} 
          current={filter.page} 
          onChange={(e) => changeFilter({ page: e })} 
          pageSize={filter.limit} 
          showTotal={(total) => `Total ${total} items`} 
          onShowSizeChange={(e, selectedNumber) => {changeFilter({ limit: selectedNumber});}} 
          defaultPageSize={20}
          showSizeChanger />
      </Card>

      <ModalFilter
        isOpen={isFilterModalOpen}
        setIsOpen={setIsFilterModalOpen}
        filter={filter}
        changeFilter={changeFilter}
        form={form}
        sources={sources}
        countries={countries}
        commodities={commodities}
        categories={categories}
      />
      
      <ModalPostPreview
        isOpen={isPostPreviewModalOpen}
        setIsOpen={setIsPostPreviewModalOpen}
        postData={postPreviewModalInfo}
        commodities={commodities}
        countries={countries}
        sources={sources} />
    </>
  );
}

export default Posts;
