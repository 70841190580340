import service from './axiosRequest';
import { CONTACT } from '../core/consts/endpoints';
import {RequestFilter} from '../core/types/RequestFilter';
import {HttpRes} from '../core/dtos/httpRes';
import {AxiosResponse} from 'axios';
import {ContactRes} from '../core/dtos/contactRes';

export function getContact(params: RequestFilter): Promise<HttpRes<ContactRes>> {
  return service.get<HttpRes<ContactRes>>(CONTACT, { params })
    .then((res: AxiosResponse<HttpRes<ContactRes>>) => res.data);
}

export function markAsRead(id: string, isRead: boolean): Promise<string> {
  const isReadParam = isRead ? 'read' : 'unread';
  return service.get<string>(`${CONTACT}/${isReadParam}/${id}`)
    .then((res: AxiosResponse<string>) => res.data);
}