import {Button, Card, Form, Input, message} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {login} from '../../../services/authService';
import { LoginReq } from '../../../core/dtos/loginReq';
import { DASHBOARD } from '../../../core/consts/routes';
import {
  UserOutlined,
  LockOutlined
} from '@ant-design/icons';
import createPageTitle from '../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';

export default function Login() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const formValue: LoginReq = form.getFieldsValue();
    login(formValue)
      .then(() => navigate(`/${DASHBOARD}`))
      .catch(err => {
        messageApi.open({
          type: 'error',
          content: String(err),
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{createPageTitle('Login')}</title>
      </Helmet>
      {contextHolder}
      <div className="full-page-wrapper auth-page-wrapper">
        <img src="/img/logo.png" alt="Ecruu Logo" className="logo" />
        <Card title="Sign In">
          <Form
            form={form}
            className="login-form"
            onFinish={handleSubmit}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" style={{gap: '6px'}} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                style={{gap: '6px'}}
              />
            </Form.Item>

            <Form.Item noStyle>
              <Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>
          Log in
              </Button>
            </Form.Item>
            <Link to="/forgot-password" style={{ marginTop:'8px', width: '100%', textAlign: 'center' }}>
              Forgot password
            </Link>
          </Form>
        </Card>
      </div>
    </>
  );
}
