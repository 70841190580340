import { AxiosResponse } from 'axios';
import service from './axiosRequest';
import {LoginStaticsRes} from '../core/dtos/loginStaticsRes';
import {ChartRequestFilter} from '../core/types/ChartRequestFilter';
import {DashboardBadgeRes} from '../core/dtos/dashboardBadgeRes';
import {RankingActiveUserRes} from '../core/dtos/rankingActiveUserRes';
import {UserAppUsageRes} from '../core/dtos/userAppUsageRes';
import {RankingActiveUsersRequestFilter} from '../core/types/RankingActiveUsersRequestFilter';
import {AppUsageRequestFilter} from '../core/types/AppUsageRequestFilter';
import {
  DASHBOARD_APP_USAGE,
  DASHBOARD_BADGE,
  DASHBOARD_LOGIN_STATICS,
  DASHBOARD_RANKING_ACTIVE_USERS
} from '../core/consts/endpoints';

export function getLoginStatistics(filter: ChartRequestFilter): Promise<LoginStaticsRes> {
  return service.get<LoginStaticsRes>(DASHBOARD_LOGIN_STATICS, { params: filter })
    .then((res: AxiosResponse<LoginStaticsRes>) => res.data);
}

export function getDashboardBadge(): Promise<DashboardBadgeRes> {
  return service.get<DashboardBadgeRes>(DASHBOARD_BADGE)
    .then((res: AxiosResponse<DashboardBadgeRes>) => res.data);
}

export function getRankingActiveUsers(params?: RankingActiveUsersRequestFilter): Promise<RankingActiveUserRes[]> {
  return service.get<RankingActiveUserRes[]>(DASHBOARD_RANKING_ACTIVE_USERS, { params })
    .then((res: AxiosResponse<RankingActiveUserRes[]>) => res.data);
}

export function getUserAppUsage(userId: string, params?: AppUsageRequestFilter): Promise<UserAppUsageRes> {
  const endpoint = `${DASHBOARD_APP_USAGE}/${userId}`;
  return service.get<UserAppUsageRes>(endpoint, { params })
    .then((res: AxiosResponse<UserAppUsageRes>) => {
      return res.data;
    });
}
