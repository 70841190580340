import './css/generate-mail.scss';
import React, {useEffect, useState} from 'react';
import {Button, Card, Col, DatePicker, Form, message, Row, Select, Spin, TimePicker} from 'antd';
import {Helmet} from 'react-helmet';
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd';
import dayjs, {Dayjs} from 'dayjs';
import createPageTitle from '../../../../utils/createPageTitle';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import {CommodityRes} from '../../../../core/dtos/commodityRes';
import {getAllCommodities} from '../../../../services/commodityService';
import Column from './components/Column';
import MailPreview from './components/MailPreview';
import {getPosts} from '../../../../services/postService';
import {MailGeneratorPostForm} from '../../../../core/types/MailGeneratorPostForm';
import mailGeneratorData from '../../../../core/consts/mailGeneratorData';
import {IMailGeneratorDragAndDrop} from '../../../../core/types/MailGeneratorDragAndDrop';
import {LoadingOutlined} from '@ant-design/icons';
import MAIL_GENERATOR_CLIPBOARD_HTML from './consts/mailTemplate';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Handlebars from 'handlebars/dist/cjs/handlebars';

export default function MailGenerator() {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const currentDate = new Date();
  const [commodities, setCommodities] = useState<CommodityRes[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [mailDisplayDate, setMailDisplayDate] = useState<Dayjs|null>(null);
  const [mailCommodityTitle, setMailCommodityTitle] = useState<string>('');
  const [dragAndDropItems, setDragAndDropItems] = useState<IMailGeneratorDragAndDrop[]>([]);
  const [dragAndDropEditItemId, setDragAndDropEditItemId] = useState<string|null>(null);
  const [mailTemplateResult, setMailTemplateResult] = useState<string>('');

  useEffect(() => {
    const cloneMailGeneratorData = [...mailGeneratorData];
    setDragAndDropItems(cloneMailGeneratorData);

    getAllCommodities()
      .then((body) => {
        setCommodities(body.filter(commodity => commodity.name === 'Sugar' || commodity.name === 'Ethanol'));
      });
  }, []);

  const handlePostRequestFormSubmit = (e: MailGeneratorPostForm) => {
    setIsDataLoading(true);

    setMailCommodityTitle(commodities?.find(commodity => commodity?._id === e?.commodity)?.name?.toUpperCase() || '');

    getPosts({
      commodities: [e?.commodity || ''],
      fromPublishedAt: e?.fromPublishedAt?.toISOString() || dayjs(currentDate.setDate(currentDate.getDate() - 1)).toISOString(),
      toPublishedAt: e?.toPublishedAt?.toISOString(),
      limit: 1000
    })
      .then((body) => {
        const cloneMailGenerator: IMailGeneratorDragAndDrop[] = structuredClone(mailGeneratorData);

        if (body.totalItemCount > body.pagination.itemCount) {
          messageApi.open({
            type: 'warning',
            content: 'Maximum 1000 post allowed, please reduce date range',
          });
        }

        body.items.map((post) => {
          // Map drag and drop items
          cloneMailGenerator.map((column, index) => {
            // Check if column countries includes post country
            if (column.countries.includes(Number(post?.countries[0]?.id))) {
              // Then push this post to cloneDragAndDrop
              cloneMailGenerator[index].posts.push({
                ...post,
                isVisible: true,
                fullTitle: post.countries?.[0]?.name?.toUpperCase() + ' - ' + post.headline
              });
            }
          });
        });
        setDragAndDropItems(cloneMailGenerator);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };

  useEffect(() => {
    const template = Handlebars.compile(MAIL_GENERATOR_CLIPBOARD_HTML);
    setMailTemplateResult(template({
      mailDisplayDate: mailDisplayDate?.format('DD MMMM YYYY') || dayjs().format('DD MMMM YYYY'),
      mailCommodityTitle,
      data: dragAndDropItems,
    }));
  }, [dragAndDropItems]);

  const onDragEnd = ({ destination, source, type }: DropResult ) => {
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // Column yer değiştiriyorsa
    if (type === 'column') {
      const cloneDragAndDropItems = [...dragAndDropItems];

      const replacedColumn = {...dragAndDropItems[source.index]};
      cloneDragAndDropItems.splice(source.index, 1);
      cloneDragAndDropItems.splice(destination.index, 0, replacedColumn);

      setDragAndDropItems([ ...cloneDragAndDropItems ]);
      return;
    }

    // Kendi Column içerisinde yer değiştiriyorsa
    if (source.droppableId === destination.droppableId) {
      const cloneDragAndDropItems = [...dragAndDropItems];
      const foundColumn = cloneDragAndDropItems.find(item => item.id == destination.droppableId);
      const indexOfColumn = foundColumn ? cloneDragAndDropItems.indexOf(foundColumn) : 0;
      const column = cloneDragAndDropItems[indexOfColumn];

      const replacedPost = {...column.posts[source.index]};
      column.posts.splice(source.index, 1);

      column.posts.splice(destination.index, 0, replacedPost);

      setDragAndDropItems(cloneDragAndDropItems);
      return;
    }

    // Bir Column'dan başka bir Column'a geçiyorsa
    const cloneDragAndDropItems = [...dragAndDropItems];

    const foundStart = cloneDragAndDropItems.find(item => item.id == source.droppableId);
    const indexOfStart = foundStart ? cloneDragAndDropItems.indexOf(foundStart) : 0;
    const startPosts = cloneDragAndDropItems[indexOfStart].posts;

    const foundEnd = cloneDragAndDropItems.find(item => item.id == destination.droppableId);
    const indexOfEnd = foundEnd ? cloneDragAndDropItems.indexOf(foundEnd) : 0;
    const endPosts = cloneDragAndDropItems[indexOfEnd].posts;

    const newPost = {...startPosts[source.index]};
    startPosts.splice(source.index, 1);
    endPosts.splice(destination.index, 0, newPost);

    setDragAndDropItems(cloneDragAndDropItems);
  };

  return (
    <>
      <Helmet>
        <title>{createPageTitle('Mail Generator')}</title>
      </Helmet>
      {contextHolder}
      <HeaderBreadcrumb items={['Mail Generator']} />
      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <Card title="Mail Generator">
            <Form
              name='Get posts'
              form={form}
              onFinish={handlePostRequestFormSubmit}
              layout='vertical'>
              <Row gutter={[24, 0]}>
                <Col xs={12}>
                  <Form.Item
                    name='fromPublishedAt'
                    label='Start Date'
                  >
                    <DatePicker
                      size="large"
                      placeholder="Start date"
                      style={{width: '100%'}}
                      showTime={{
                        hideDisabledOptions: true,
                      }}
                      format="YYYY-MM-DD HH:mm" />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name='toPublishedAt'
                    label='End Date'
                  >
                    <DatePicker
                      size="large"
                      placeholder="End date"
                      style={{width: '100%'}}
                      showTime={{
                        hideDisabledOptions: true,
                      }}
                      format="YYYY-MM-DD HH:mm" />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name='mailDisplayDate'
                    label='Mail Display Date'
                  >
                    <DatePicker
                      size="large"
                      placeholder="Mail display date"
                      style={{width: '100%'}}
                      value={mailDisplayDate}
                      onChange={(event) => setMailDisplayDate(event)} />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name='commodity'
                    label='Commodity'
                  >
                    <Select
                      size='large'
                      placeholder='Commodity'
                      fieldNames={{ value: '_id', label: 'name' }}
                      options={commodities?.sort()}
                      style={{width: '100%'}}
                      filterOption={(input: string, option: any) =>
                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      showSearch
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} style={{display: 'flex', justifyContent: 'center'}}>
                  <Button
                    type="primary"
                    htmlType='submit'
                    shape="default"
                    icon={<Spin spinning={isDataLoading} indicator={<LoadingOutlined style={{ fontSize: 18, color: 'white', marginRight: '12px' }} spin />} />}
                    size="large"
                    style={{width: '300px'}}>
                    Load Posts
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xs={24}>
          <Card>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="all-column" type="column" direction="vertical">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className="drag-and-drop-mail-generator"
                    {...provided.droppableProps}
                  >
                    {dragAndDropItems.map((column, index) => (
                      <Column
                        index={index}
                        key={column.id}
                        column={column}
                        posts={column.posts}
                        dragAndDropEditItemId={dragAndDropEditItemId}
                        setDragAndDropEditItemId={setDragAndDropEditItemId}
                        dragAndDropItems={dragAndDropItems}
                        setDragAndDropItems={setDragAndDropItems}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Card>
        </Col>
        <Col xs={24}>
          <MailPreview
            isActive={true}
            mailTemplateResult={mailTemplateResult}
            messageApi={messageApi}
          />
        </Col>
      </Row>
    </>
  );
}
