import { AxiosResponse } from 'axios';
import { COMMODITIY, COMMODITIY_ALL } from '../core/consts/endpoints';
import { CommodityRes } from '../core/dtos/commodityRes';
import { HttpRes } from '../core/dtos/httpRes';
import { RequestFilter } from '../core/types/RequestFilter';
import service from './axiosRequest';

export function getAllCommodities(): Promise<CommodityRes[]> {
  return service.get<CommodityRes[]>(COMMODITIY_ALL)
    .then((res: AxiosResponse<CommodityRes[]>) => res.data);
}

export function getCommodities(params: RequestFilter): Promise<HttpRes<CommodityRes>> {
  return service.get<HttpRes<CommodityRes>>(COMMODITIY, { params })
    .then((res: AxiosResponse<HttpRes<CommodityRes>>) => res.data);
}

export function createCommodity(body: CommodityRes) {
  return service.post(COMMODITIY, body)
    .then((res: AxiosResponse<CommodityRes>) => res.data);
}

export function updateCommodity(body: CommodityRes) {
  return service.patch(COMMODITIY, body)
    .then((res: AxiosResponse<CommodityRes>) => res.data);
}

export function deleteCommodity(id: string) {
  return service.delete(`${COMMODITIY}/${id}`);
}
