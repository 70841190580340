import {DashboardBadgeRes} from '../dtos/dashboardBadgeRes';
import {
  BLOG_COMMENTS,
  BLOGS,
  CATEGORIES,
  COMMODITIES,
  CONTACT,
  COUNTRIES,
  DASHBOARD, MAIL_GENERATOR,
  NOTIFICATIONS,
  POSTS,
  SOURCES,
  SUBSCRIBERS,
  USERS
} from './routes';
import {
  AppstoreOutlined,
  BellOutlined,
  CommentOutlined,
  FileTextOutlined,
  FlagOutlined,
  FormOutlined, MailOutlined,
  MessageOutlined, NotificationOutlined,
  OrderedListOutlined, PartitionOutlined,
  PieChartOutlined,
  ProfileOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';

export const SIDEBAR_MENU = (dashboardBadge?: DashboardBadgeRes) => [
  {
    id: 1,
    path: DASHBOARD,
    label: 'Dashboard',
    icon: <PieChartOutlined />
  },
  {
    id: 2,
    path: CONTACT,
    label: `Contact (${dashboardBadge?.unreadContactMessage})`,
    icon: <MessageOutlined />
  },
  {
    id: 3,
    label: 'Blog',
    icon: <FormOutlined />,
    path: BLOGS,
    children: [
      {
        path: BLOGS,
        label: 'Blogs',
        icon: <OrderedListOutlined />,
      },
      {
        path: BLOG_COMMENTS,
        label: `Comments (${dashboardBadge?.unapprovedComments})`,
        icon: <CommentOutlined />,
      }
    ]
  },
  {
    id: 4,
    label: 'Post',
    icon: <ProfileOutlined />,
    path: POSTS,
    children: [
      {
        path: POSTS,
        label: 'Posts',
        icon: <OrderedListOutlined />,
      },
      {
        path: SOURCES,
        label: 'Sources',
        icon: <FileTextOutlined />,
      },
      {
        path: CATEGORIES,
        label: 'Categories',
        icon: <PartitionOutlined />
      },
      {
        path: COUNTRIES,
        label: 'Countries',
        icon: <FlagOutlined />
      },
      {
        path: COMMODITIES,
        label: 'Commodities',
        icon: <AppstoreOutlined />
      },
    ]
  },
  {
    id: 5,
    label: 'Subscribers',
    icon: <NotificationOutlined />,
    path: SUBSCRIBERS,
  },
  {
    id: 6,
    path: NOTIFICATIONS,
    label: 'Mobile Notification',
    icon: <BellOutlined />
  },
  {
    id: 7,
    label: 'Users',
    icon: <UsergroupAddOutlined />,
    path: USERS,
  },
  {
    id: 8,
    label: 'Mail Generator',
    icon: <MailOutlined />,
    path: MAIL_GENERATOR,
  },
];
