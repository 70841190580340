import { Menu, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import {useState} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  LogoutOutlined,
} from '@ant-design/icons';
import SubMenu from 'antd/es/menu/SubMenu';
import {useApp} from '../context/siteContext';
import {DashboardBadgeRes} from '../core/dtos/dashboardBadgeRes';
import {SIDEBAR_MENU} from '../core/consts/sidebarMenu';

export default function Sidebar() {
  const { pathname } = useLocation();
  const { dashboardBadge }: { dashboardBadge?: DashboardBadgeRes } = useApp();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const navLogo = (isCollapsed: boolean) =>
    isCollapsed 
      ? <img src="/img/favicon.svg" className='sidebar-small-logo' />
      : <img src="/img/logo.png" className='sidebar-logo' />;
        
  return (
    <Sider
      width={230}
      collapsed={collapsed} 
      onCollapse={(value) => setCollapsed(value)}
      collapsible >
      <NavLink 
        to="/dashboard"
        style={{textAlign: 'center'}}>
        {navLogo(collapsed)}
      </NavLink>
      <Menu theme="dark" mode="inline" selectable={false}>
        {SIDEBAR_MENU(dashboardBadge).map((item, index) => (
          item.children ? (
            <SubMenu key={item.id} title={item.label} icon={item.icon} className={item.path === pathname.split('/')[1] ? 'sidebar-submenu-active' : ''}>
              {item.children.map(child => (
                <Menu.Item
                  key={child.path}
                  datatype="group"
                  style={{ background: (pathname === `/${child.path}` ? colorPrimary : '')}}>
                  <NavLink to={`/${child.path}`}>
                    {child.icon}
                    <span className="icon">{child.label}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={item.id}
              datatype="group"
              style={{ background: (pathname === `/${item.path}` ? colorPrimary : '')}}>
              <NavLink to={`/${item.path}`}>
                {item.icon}
                <span className="icon">{item.label}</span>
              </NavLink>
            </Menu.Item>
          )

        ))}
        <Menu.Item key="logout" style={{color: 'rgb(221 54 54)', background: 'none'}}>
          <NavLink to="/login">
            <LogoutOutlined />
            <span className="icon">Logout</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
