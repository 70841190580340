import createPageTitle from '../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>{createPageTitle('Not Found')}</title>
      </Helmet>

      <div className="full-page-wrapper">
        <h1>404 Not Found</h1>
      </div>
    </>
  );
}
