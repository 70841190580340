import {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import AuthLayout from './presentations/auth/components/Layout';
import MainLayout from './presentations/main/components/Layout';
import Login from './presentations/auth/pages/Login';
import ForgotPassword from './presentations/auth/pages/ForgotPassword';
import ResetPassword from './presentations/auth/pages/ResetPassword';
import Categories from './presentations/main/pages/Categories';
import Countries from './presentations/main/pages/Countries';
import Dashboard from './presentations/main/pages/Dashboard';
import NotFound from './presentations/main/pages/NotFound';
import Posts from './presentations/main/pages/Posts';
import Post from './presentations/main/pages/Posts/Post';
import Sources from './presentations/main/pages/Sources';
import Subscribers from './presentations/main/pages/Subscribers';
import Users from './presentations/main/pages/Users';
import User from './presentations/main/pages/Users/User';
import Commodities from './presentations/main/pages/Commodities';
import Subscriber from './presentations/main/pages/Subscribers/Subscriber';
import Notifications from './presentations/main/pages/Notifications';
import Blog from './presentations/main/pages/Blogs/Blog';
import Blogs from './presentations/main/pages/Blogs';
import BlogComments from './presentations/main/pages/Blogs/Comments';
import Contact from './presentations/main/pages/Contact';
import MailGenerator from './presentations/main/pages/MailGenerator';
import {
  BLOG_COMMENTS,
  BLOGS,
  CATEGORIES,
  COMMODITIES,
  CONTACT,
  COUNTRIES,
  DASHBOARD,
  FORGOT_PASSWORD,
  LOGIN,
  MAIL_GENERATOR,
  NOTIFICATIONS,
  POSTS,
  RESET_PASSWORD,
  SOURCES,
  SUBSCRIBERS,
  USERS
} from './core/consts/routes';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route
        path="/"
        element={<MainLayout />}>
        <Route index element={<Navigate to={DASHBOARD} />} />
        <Route path={DASHBOARD} element={<Dashboard />} />
        <Route path={CONTACT} element={<Contact />} />
        <Route path={POSTS} element={<Posts />} />
        <Route path={`${POSTS}/create`} element={<Post />} />
        <Route path={`${POSTS}/:id`} element={<Post />} />
        <Route path={BLOGS} element={<Blogs />} />
        <Route path={`${BLOGS}/create`} element={<Blog />} />
        <Route path={`${BLOGS}/:id`} element={<Blog />} />
        <Route path={BLOG_COMMENTS} element={<BlogComments />} />
        <Route path={SOURCES} element={<Sources />} />
        <Route path={SUBSCRIBERS} element={<Subscribers />} />
        <Route path={`${SUBSCRIBERS}/create`} element={<Subscriber />} />
        <Route path={`${SUBSCRIBERS}/:id`} element={<Subscriber />} />
        <Route path={NOTIFICATIONS} element={<Notifications />} />
        <Route path={USERS} element={<Users />} />
        <Route path={`${USERS}/create`} element={<User />} />
        <Route path={`${USERS}/:id`} element={<User />} />
        <Route path={CATEGORIES} element={<Categories />} />
        <Route path={COUNTRIES} element={<Countries />} />
        <Route path={COMMODITIES} element={<Commodities />} />
        <Route path={MAIL_GENERATOR} element={<MailGenerator />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route
        path="/"
        element={<AuthLayout />}>
        <Route path={LOGIN} element={<Login />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={RESET_PASSWORD} element={<ResetPassword />} />
      </Route>
    </Routes>
  );
}

export default App;
