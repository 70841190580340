import {useEffect, useState} from 'react';
import {HttpRes} from '../../../../core/dtos/httpRes';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import formatDate from '../../../../utils/formatDate';
import Loader from '../../../../components/Loader';
import Pagination from 'antd/lib/pagination';
import {Helmet} from 'react-helmet';
import createPageTitle from '../../../../utils/createPageTitle';
import {Button, Card, Col, message, Modal, Popconfirm, Row, Table, Tooltip} from 'antd';
import {CheckOutlined, DeleteOutlined, EyeOutlined,} from '@ant-design/icons';
import {approveComment, deleteComment, getComments} from '../../../../services/commentService';
import {CommentRes} from '../../../../core/dtos/commentRes';
import {CommentRequestFilter} from '../../../../core/types/CommentRequestFilter';
import {CommentStatus} from '../../../../core/enums/CommentStatus';
import {Link} from 'react-router-dom';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Website',
    dataIndex: 'website',
    key: 'website',
  },
  {
    title: 'Content',
    key: 'content',
    dataIndex: 'content',
  },
  {
    title: 'Created At',
    key: 'createdAt',
    dataIndex: 'createdAt',
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
  }
];

function BlogComments() {
  const [messageApi, contextHolder] = message.useMessage();
  const [approvedComments, setApprovedComments] = useState<HttpRes<CommentRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });
  const [pendingComments, setPendingComments] = useState<HttpRes<CommentRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });
  const [approvedCommentsFilter, setApprovedCommentsFilter] = useState<CommentRequestFilter>({
    page: 1,
    limit: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    status: CommentStatus.APPROVED
  });
  const [pendingCommentsFilter, setPendingCommentsFilter] = useState<CommentRequestFilter>({
    page: 1,
    limit: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    status: CommentStatus.PENDING
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentCommentDetails, setCurrentCommentDetails] = useState<CommentRes|null>(null);

  const mapComments = (comments: HttpRes<CommentRes>) => comments.items.map(comment => (
    {
      key: comment._id,
      name: <b>{comment.name}</b>,
      email: <span>{comment.email}</span>,
      website: <span>{comment.website}</span>,
      content: <span>{comment.content.slice(0, 20)}</span>,
      createdAt: <span>{formatDate(comment.createdAt)}</span>,
      actions: (
        <Row className="ant-employed table-actions" gutter={[8, 0]}>
          {comment.status === CommentStatus.PENDING && (
            <Col>
              <Popconfirm
                placement="bottomRight"
                title='Approve the Comment'
                description='Are you sure to approve this comment?'
                onConfirm={() => {
                  approveComment(comment._id)
                    .then(() => {
                      messageApi.open({
                        type: 'success',
                        content: 'Comment successfully approved',
                      });
                      handleGetComments(CommentStatus.APPROVED);
                      handleGetComments(CommentStatus.PENDING);
                    });
                }}
                okText="Approve"
                cancelText="Cancel"
              >
                <Tooltip title="Approve Comment">
                  <Button
                    type="primary"
                    shape="circle"
                    style={{ background: '#1677ff' }}
                    icon={<CheckOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Col>
          )}
          <Col>
            <Tooltip title="View More">
              <Button
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => setCurrentCommentDetails(comment)} />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              placement="bottomRight"
              title='Delete the Comment'
              description='Are you sure to delete this comment?'
              onConfirm={() => {
                deleteComment(comment._id)
                  .then(() => {
                    handleGetComments(comment.status);
                  });
              }}
              okText="Delete"
              cancelText="Cancel"
            >
              <Tooltip title="Delete Comment">
                <Button 
                  danger
                  type="primary" 
                  shape="circle" 
                  icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    }
  ));

  const handleGetComments = (filterType: CommentStatus) => {
    if (filterType === CommentStatus.PENDING) {
      getComments(pendingCommentsFilter)
        .then((body: HttpRes<CommentRes>) => {

          setPendingComments(body);
        })
        .finally(() => setIsLoading(false));
    } else {
      getComments(approvedCommentsFilter)
        .then((body: HttpRes<CommentRes>) => {

          setApprovedComments(body);
        })
        .finally(() => setIsLoading(false));
    }

  };

  useEffect(() => {
    handleGetComments(CommentStatus.PENDING);
  }, [pendingCommentsFilter]);

  useEffect(() => {
    handleGetComments(CommentStatus.APPROVED);
  }, [approvedCommentsFilter]);

  const changeFilter = (key: CommentStatus, changedFilter: Partial<CommentRequestFilter>) => {
    if (key === CommentStatus.PENDING) {
      setPendingCommentsFilter((filter) => ({
        ...filter,
        ...changedFilter
      }));
    } else {
      setApprovedCommentsFilter((filter) => ({
        ...filter,
        ...changedFilter
      }));
    }
  };

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle('Blog Comments')}</title>
      </Helmet>
      {contextHolder}
      <div className='breadcrumb-wrapper'>
        <HeaderBreadcrumb items={['Blog', 'Comments']} />
      </div>
      <div className="tabled">
        <Row gutter={[24, 48]}>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Pending Comments">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={mapComments(pendingComments)}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>

            <Card style={{marginTop: '16px', justifyContent: 'center', display:'flex'}}>
              <Pagination total={pendingComments.totalItemCount}
                current={pendingCommentsFilter.page}
                onChange={(e) => changeFilter(CommentStatus.PENDING,{ page: e })}
                pageSize={pendingCommentsFilter.limit}
                showTotal={(total) => `Total ${total} items`}
                onShowSizeChange={(e, selectedNumber) => changeFilter(CommentStatus.PENDING, { limit: selectedNumber })}
                defaultPageSize={20}
                showSizeChanger />
            </Card>
          </Col>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Approved Comments">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={mapComments(approvedComments)}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>

            <Card style={{marginTop: '16px', justifyContent: 'center', display:'flex'}}>
              <Pagination total={approvedComments.totalItemCount}
                current={approvedCommentsFilter.page}
                onChange={(e) => changeFilter(CommentStatus.APPROVED, { page: e })}
                pageSize={approvedCommentsFilter.limit}
                showTotal={(total) => `Total ${total} items`}
                onShowSizeChange={(e, selectedNumber) => changeFilter(CommentStatus.APPROVED,{ limit: selectedNumber })}
                defaultPageSize={20}
                showSizeChanger />
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        centered
        title={`${currentCommentDetails?.name}'s Comment`}
        open={!!currentCommentDetails}
        onOk={() => setCurrentCommentDetails(null)}
        onCancel={() => setCurrentCommentDetails(null)}
        width={800}
        style={{marginTop: '28px', marginBottom: '28px'}}
      >
        <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
          <b>Email:</b>
          <p style={{margin: '4px'}}>{currentCommentDetails?.email}</p>
        </div>
        {currentCommentDetails?.website && (
          <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
            <b>Website:</b>
            <p style={{margin: '4px'}}>{currentCommentDetails.website}</p>
          </div>
        )}
        <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
          <b>Related Blog:</b>
          <a href={`${process.env.REACT_APP_WEB_URL}blogs/${currentCommentDetails?.blog.permalink}` || ''}
            style={{margin: '4px'}}
            target="_blank"
            rel="noreferrer">
            {currentCommentDetails?.blog.title}
          </a>
        </div>
        <p>
          {currentCommentDetails?.content}
        </p>
      </Modal>
    </>
  );
}

export default BlogComments;
