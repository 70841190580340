import { Outlet } from 'react-router-dom';
import {useEffect} from 'react';
import {logout} from '../../../services/authService';
import storageService from '../../../services/storageService';
import {ACCESS_TOKEN} from '../../../core/consts/tokens';

export default function AuthLayout() {
  useEffect(() => {
    if (storageService.get(ACCESS_TOKEN))
      logout();
  }, []);
  
  return (
    <Outlet />
  );
}
