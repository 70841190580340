import { AxiosResponse } from 'axios';
import { SOURCE, SOURCE_ALL } from '../core/consts/endpoints';
import { HttpRes } from '../core/dtos/httpRes';
import { SourceReq } from '../core/dtos/sourceReq';
import { SourceRes } from '../core/dtos/sourceRes';
import { SourceUpdateReq } from '../core/dtos/sourceUpdateReq';
import { SourceRequestFilter } from '../core/types/SourceRequestFilter';
import service from './axiosRequest';

export function getAllSources(): Promise<SourceRes[]> {
  return service.get<SourceRes[]>(SOURCE_ALL)
    .then((res: AxiosResponse<SourceRes[]>) => res.data);
}

export function getSources(params: SourceRequestFilter): Promise<HttpRes<SourceRes>> {
  return service.get<HttpRes<SourceRes>>(SOURCE, { params })
    .then((res: AxiosResponse<HttpRes<SourceRes>>) => res.data);
}

export function createSource(body: SourceReq) {
  return service.post(SOURCE, body)
    .then((res: AxiosResponse<SourceRes>) => res.data);
}

export function updateSource(body: SourceUpdateReq) {
  return service.patch(SOURCE, body)
    .then((res: AxiosResponse<SourceRes>) => res.data);
}

export function deleteSource(id: string) {
  return service.delete(`${SOURCE}/${id}`)
    .then((res: AxiosResponse<SourceRes>) => res.data);
}
