import {Button, Card, Col, Form, Input, message} from 'antd';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {resetPassword} from '../../../services/authService';
import {ResetPasswordReq} from '../../../core/dtos/resetPasswordReq';
import {LOGIN} from '../../../core/consts/routes';
import createPageTitle from '../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';
import {useEffect, useState} from 'react';
import {Row} from 'antd/lib';
import {
  MailOutlined,
  LockOutlined
} from '@ant-design/icons';

export default function ResetPassword() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [params] = useSearchParams();
  const [email, setEmail] = useState<string>('');

  const handleSubmit = async () => {
    const formValue: ResetPasswordReq = form.getFieldsValue();
    resetPassword(formValue, ('Bearer ' + params.get('token') || ''))
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Your password successfully updated',
        })
          .then(() => {
            navigate(`/${LOGIN}`);
          });
      })
      .catch(err => {
        messageApi.open({
          type: 'error',
          content: String(err.response.data.message),
        });
      });
  };

  useEffect(() => {
    setEmail(params.get('email') || '');
  }, []);

  return (
    <>
      <Helmet>
        <title>{createPageTitle('Reset Password')}</title>
      </Helmet>
      {contextHolder}
      <div className="full-page-wrapper auth-page-wrapper">
        <img src="/img/logo.png" alt="Ecruu Logo" id="logo" />
        <Card title="Reset Password">
          <Form
            form={form}
            className="reset-password-form"
            onFinish={handleSubmit}
          >
            <Row gutter={[0, 12]}>
              <Col xs={24}>
                <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" disabled={true} style={{gap: '6px'}} value={email} />
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a password!',
                    },
                    {
                      min: 3,
                      message: 'Password must be longer than 2 characters!',
                    },
                  ]}
                >
                  <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="New Password" style={{gap: '6px'}} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit" className="save-form-button" style={{width: '100%'}}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
}
