import {ChartData} from '../core/types/ChartData';

const generateChartData = (chartData: ChartData[]) => ({
  options: {
    xaxis: {
      categories: chartData.map((item) =>item.day)
    }
  },
  series: [
    {
      name: 'Mobile',
      data: chartData.map((item) => item.totalCount - item.browserCount)
    },
    {
      name: 'Browser',
      data: chartData.map((item) =>item.browserCount)
    },
    {
      name: 'Total',
      data: chartData.map((item) =>item.totalCount)
    },
  ]
});

export default generateChartData;
