import { AxiosResponse } from 'axios';
import { CATEGORY, CATEGORY_ALL } from '../core/consts/endpoints';
import { CategoryReq } from '../core/dtos/categoryReq';
import { CategoryRes } from '../core/dtos/categoryRes';
import { HttpRes } from '../core/dtos/httpRes';
import { RequestFilter } from '../core/types/RequestFilter';
import service from './axiosRequest';

export function getAllCategories(): Promise<CategoryRes[]> {
  return service.get<CategoryRes[]>(CATEGORY_ALL)
    .then((res: AxiosResponse<CategoryRes[]>) => res.data);
}

export function getCategories(params: RequestFilter): Promise<HttpRes<CategoryRes>> {
  return service.get<HttpRes<CategoryRes>>(CATEGORY, { params })
    .then((res: AxiosResponse<HttpRes<CategoryRes>>) => res.data);
}

export function createCategory(body: CategoryReq) {
  return service.post(CATEGORY, body)
    .then((res: AxiosResponse<CategoryReq>) => res.data);
}

export function updateCategory(body: CategoryReq) {
  return service.patch(CATEGORY, body)
    .then((res: AxiosResponse<CategoryReq>) => res.data);
}

export function deleteCategory(id: string) {
  return service.delete(`${CATEGORY}/${id}`);
}
