export default function formatDate(dateTime: string): string {
  if (!dateTime) {
    return '';
  }
  return new Intl.DateTimeFormat('en-GB',
    {
      month:'2-digit',
      day:'2-digit',
      year:'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    })
    .format(new Date(dateTime))
    .toString();
}
