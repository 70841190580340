import {Input, Select} from 'antd';
import Search from 'antd/es/transfer/search';
import HeaderBreadcrumb from '../../../components/Breadcrumb';
import {useEffect, useMemo, useRef, useState} from 'react';
import { HttpRes } from '../../../core/dtos/httpRes';
import Loader from '../../../components/Loader';
import formatDate from '../../../utils/formatDate';
import Pagination from 'antd/lib/pagination';
import {
  Row,
  Form,
  Col,
  Card,
  Table,
  Button,
  Tooltip,
  DatePicker,
  Popconfirm,
  message,
} from 'antd';
import {
  EditOutlined,
  SaveOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { CategoryRes } from '../../../core/dtos/categoryRes';
import { RequestFilter } from '../../../core/types/RequestFilter';
import { createCategory, deleteCategory, getCategories, updateCategory } from '../../../services/categoryService';
import { CategoryReq } from '../../../core/dtos/categoryReq';
import {debounce} from 'lodash';
import createPageTitle from '../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';
import getDateFromDatePicker from '../../../utils/getDateFromDatePicker';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Parent Category',
    dataIndex: 'parentCategory',
    key: 'parentCategory',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];

const generateFilterParams = (filter: RequestFilter): RequestFilter => ({
  limit: filter.limit || undefined,
  name: filter.name || undefined,
  page: filter.page || undefined,
  fromCreatedAt: filter.fromCreatedAt || undefined,
  toCreatedAt: filter.toCreatedAt || undefined,
});

function Categories() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [categories, setCategories] = useState<HttpRes<CategoryRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [edit, setEdit] = useState<CategoryReq>({
    _id: '',
    name: '',
    parent: {
      _id: '',
      name: ''
    }
  });
  const [filter, setFilter] = useState<RequestFilter>({
    limit: 20,
    name: '',
    page: 1,
    fromCreatedAt: '',
    toCreatedAt: '',
  });

  const changeFilter = (changedFilter: Partial<RequestFilter>) => {
    if (!changedFilter.page) {
      setFilter({
        ...filter,
        page: 1
      });
    }
    setFilter((filter) => ({
      ...filter,
      ...changedFilter
    }));
  };

  const mapCategories = () => categories.items.map(category => (
    {
      key: category._id,
      name: (
        <b>{category.name}</b>
      ),
      parentCategory: (
        <span>{category.parent?.name || '-'}</span>
      ),
      createdAt: (
        <span>{formatDate(category.createdAt)}</span>
      ),
      actions: (
        <>
          <Row className="ant-employed" gutter={[8, 0]} style={{justifyContent: 'flex-end'}}>
            <Col>
              <Tooltip title="Edit">
                <a href="#form">
                  <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => {setEdit({_id: category._id, name: category.name, parent: {_id: category.parent?._id || '', name: category.parent?.name || ''}}), form.setFieldsValue({name: category.name, parent: category.parent?._id});}} />
                </a>
              </Tooltip>
            </Col>
            <Col>
              <Popconfirm
                placement="bottomRight"
                title='Delete the Category'
                description='Are you sure to delete this category?'
                onConfirm={() => {
                  deleteCategory(category._id)
                    .then(() => {
                      handleGetCategories();
                    });
                }}
                okText="Delete"
                cancelText="Cancel"
              >
                <Tooltip title="Delete">
                  <Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        </>
      ),
    }
  ));

  const handleGetCategories = () => {
    const filterParams: RequestFilter = generateFilterParams(filter);

    getCategories(filterParams)
      .then((body: HttpRes<CategoryRes>) => setCategories(body))
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = () => {
    if (edit._id !== '') {
      updateCategory({...form.getFieldsValue(), _id: edit._id })
        .then(() => {
          handleGetCategories();
          setEdit({
            name: '',
            _id: '',
            parent: {
              _id: '',
              name: ''
            }
          });
          form.resetFields();
          messageApi.open({
            type: 'success',
            content: 'Category successfully updated',
          });
        });
    } else {
      createCategory(form.getFieldsValue())
        .then(() => {
          handleGetCategories();
          form.resetFields();
          messageApi.open({
            type: 'success',
            content: 'Category successfully created',
          });
        });
    }
  };

  const handleSearchWithDebounce = useMemo(() => {
    return debounce(
      (e) => changeFilter({ name: e.target.value }),
      500
    );
  }, []);

  useEffect(() => {
    handleGetCategories();
  }, [filter]);

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle('Categories')}</title>
      </Helmet>
      {contextHolder}
      <HeaderBreadcrumb items={['Categories']} />
      <div className="tabled">
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <Form name='Category' form={form} onFinish={handleSubmit} layout='vertical' id='form'>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Create Category"
                extra={
                  <Button type="primary" htmlType='submit' shape="default" icon={<SaveOutlined />} size="large" style={{width: '120px'}}>
                    {edit._id !== '' ? 'Save' : 'Create'}
                  </Button>
                }
              >
                <div className="table-responsive">
                  <Row gutter={[24, 0]}>
                    <Col xs={24} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <Form.Item
                        name='name'
                        rules={[{ required: true, message: 'Please enter category name' }, { min: 3, message: 'Category name must be longer than 2 characters' }]}
                        label='Category Name'
                        style={{width: '100%'}}
                      >
                        <Input placeholder="Category Name" size='large' />
                      </Form.Item>
                    </Col>
                    <Col xs={24} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <Form.Item
                        name='parent'
                        label='Parent'
                        style={{width: '100%'}}
                      >
                        <Select
                          size='large'
                          style={{width: '100%'}}
                          placeholder='Select a Parent'
                          fieldNames={{ value: '_id', label: 'name' }}
                          options={categories.items?.sort()}
                          filterOption={(input: string, option: any) =>
                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          showSearch
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Form>
          </Col>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="List of Categories"
              extra={
                <Row className='table-filter'>
                  <Col xs={12} style={{marginRight: '10px'}}>
                    <Search placeholder="Search in category name" onChange={handleSearchWithDebounce} />
                  </Col>

                  <Col xs={9}>
                    <DatePicker.RangePicker
                      onChange={(e: any) => {
                        changeFilter(getDateFromDatePicker(e));
                        changeFilter({page: 1});
                      }}
                    />
                  </Col>
                </Row>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={mapCategories()}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Card style={{marginTop: '16px', justifyContent: 'center', display:'flex'}}>
        <Pagination total={categories.totalItemCount} 
          current={filter.page} 
          onChange={(e) => changeFilter({ page: e })} 
          pageSize={filter.limit} 
          showTotal={(total) => `Total ${total} items`} 
          onShowSizeChange={(e, selectedNumber) => {changeFilter({ limit: selectedNumber});}} 
          defaultPageSize={20}
          showSizeChanger />
      </Card>
    </>
  );
}

export default Categories;
