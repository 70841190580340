import { AxiosResponse } from 'axios';
import {SUBSCRIBER, SUBSCRIBER_ALL} from '../core/consts/endpoints';
import { HttpRes } from '../core/dtos/httpRes';
import { SubscriberDetailRes } from '../core/dtos/subscriberDetailRes';
import {AllSubscriberItem, SubscriberRes} from '../core/dtos/subscriberRes';
import { SubscriberRequestFilter } from '../core/types/SubscriberRequestFilter';
import service from './axiosRequest';
import {AllSubscribersRequestFilter} from '../core/types/AllSubscribersRequestFilter';
import {SubscriberCreateReq} from '../core/dtos/subscriberCreateReq';
import {SubscriberUpdateReq} from '../core/dtos/subscriberUpdateReq';

export function getAllSubscribers(params?: AllSubscribersRequestFilter): Promise<AllSubscriberItem[]> {
  return service.get<AllSubscriberItem[]>(SUBSCRIBER_ALL, { params })
    .then((res: AxiosResponse<AllSubscriberItem[]>) => res.data);
}

export function getSubscribers(params?: SubscriberRequestFilter): Promise<HttpRes<SubscriberRes>> {
  return service.get<HttpRes<SubscriberRes>>(SUBSCRIBER, { params })
    .then((res: AxiosResponse<HttpRes<SubscriberRes>>) => res.data);
}

export function getSubscriber(id: string): Promise<SubscriberDetailRes> {
  return service.get<SubscriberDetailRes>(`${SUBSCRIBER}/${id}`)
    .then((res: AxiosResponse<SubscriberDetailRes>) => res.data);
}

export function createSubscriber(body: SubscriberCreateReq) {
  return service.post(SUBSCRIBER, body)
    .then((res: AxiosResponse<SubscriberDetailRes>) => res.data);
}

export function updateSubscriber(body: SubscriberUpdateReq) {
  return service.patch(SUBSCRIBER, body)
    .then((res: AxiosResponse<SubscriberDetailRes>) => res.data);
}

export function deleteSubscriber(id: string) {
  return service.delete(`${SUBSCRIBER}/${id}`);
}
