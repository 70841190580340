import { Modal } from 'antd';

interface FilterModalProps {
    header: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<boolean>;
    children: JSX.Element;
    onClose: React.Dispatch<boolean>
}

export default function ModalFilterLayout({header, isOpen, setIsOpen, onClose, children}: FilterModalProps) {
  return (
    <Modal
      centered 
      title={header}
      open={isOpen}
      onOk={() => { setIsOpen(false); onClose(true); }}
      onCancel={() => setIsOpen(false)}
      okText="Filter"
    >
      {children}
    </Modal>
  );
}
