import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

export default function Loader() {
  return (
    <div className="full-page-wrapper">
      <Spin size='large' indicator={antIcon} />
    </div>
  );
}
