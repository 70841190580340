import { AxiosResponse } from 'axios';
import {BLOG} from '../core/consts/endpoints';
import { HttpRes } from '../core/dtos/httpRes';
import service from './axiosRequest';
import {RequestFilter} from '../core/types/RequestFilter';
import {BlogRes} from '../core/dtos/blogRes';
import {BlogDetailRes} from '../core/dtos/blogDetailRes';
import {BlogReq} from '../core/dtos/blogReq';

export function getBlogs(params: RequestFilter): Promise<HttpRes<BlogRes>> {
  return service.get<HttpRes<BlogRes>>(BLOG, { params })
    .then((res: AxiosResponse<HttpRes<BlogRes>>) => res.data);
}

export function getBlog(id: string): Promise<BlogDetailRes> {
  return service.get<BlogDetailRes>(`${BLOG}/${id}`)
    .then((res: AxiosResponse<BlogDetailRes>) => res.data);
}

export function createBlog(body: BlogReq) {
  return service.post(BLOG, body)
    .then((res: AxiosResponse<BlogReq>) => res.data);
}

export function updateBlog(body: BlogReq) {
  return service.patch(BLOG, body)
    .then((res: AxiosResponse<BlogReq>) => res.data);
}

export function deleteBlog(id: string) {
  return service.delete(`${BLOG}/${id}`);
}
