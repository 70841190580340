import {Button, Card, Form, Input, message} from 'antd';
import {forgotPassword} from '../../../services/authService';
import createPageTitle from '../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';
import {ForgotPasswordReq} from '../../../core/dtos/forgotPasswordReq';
import {
  MailOutlined
} from '@ant-design/icons';

export default function ForgotPassword() {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const formValue: ForgotPasswordReq = form.getFieldsValue();
    forgotPassword(formValue)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Password reset link successfully sent to your mail',
        });
      })
      .catch(err => {
        messageApi.open({
          type: 'error',
          content: String(err.response.data.message),
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{createPageTitle('Forgot Password')}</title>
      </Helmet>
      {contextHolder}
      <div className="full-page-wrapper auth-page-wrapper">
        <img src="/img/logo.png" alt="Ecruu Logo" id="logo" />
        <Card title="Forgot password?">
          <Form
            form={form}
            className="forgot-password-form"
            onFinish={handleSubmit}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your Email!',
                },
              ]}
            >
              <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" style={{gap: '6px'}} />
            </Form.Item>
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>
                Send reset link
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
}
