import {useLocation} from 'react-router-dom';
import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {DashboardBadgeRes} from '../core/dtos/dashboardBadgeRes';
import {getDashboardBadge} from '../services/dashboardService';
import {ACCESS_TOKEN} from '../core/consts/tokens';

const AppContext = createContext({});

export const useApp = () => useContext(AppContext);

export function AppContextProvider({children}: {children: ReactNode}) {
  const location = useLocation();
  const [dashboardBadge, setDashboardBadge] = useState<DashboardBadgeRes>({
    unapprovedComments: 0,
    unreadContactMessage: 0
  });

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      getDashboardBadge()
        .then((res) => {
          setDashboardBadge(res);
        });
    }
  }, [location]);

  const values = {
    dashboardBadge
  };

  return (
    <AppContext.Provider value={values}>
      {children}
    </AppContext.Provider>
  );
}
