import { Input, Select } from 'antd';
import Search from 'antd/es/transfer/search';
import HeaderBreadcrumb from '../../../components/Breadcrumb';
import {useEffect, useMemo, useState} from 'react';
import { HttpRes } from '../../../core/dtos/httpRes';
import Loader from '../../../components/Loader';
import formatDate from '../../../utils/formatDate';
import Pagination from 'antd/lib/pagination';
import { RequestFilter } from '../../../core/types/RequestFilter';
import { CommodityRes } from '../../../core/dtos/commodityRes';
import { createCommodity, deleteCommodity, getCommodities, updateCommodity } from '../../../services/commodityService';
import {debounce} from 'lodash';
import createPageTitle from '../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';
import getDateFromDatePicker from '../../../utils/getDateFromDatePicker';
import {
  Row,
  Form,
  Col,
  Card,
  Table,
  Button,
  Tooltip,
  DatePicker,
  Popconfirm,
  message,
} from 'antd';
import {
  EditOutlined,
  SaveOutlined,
  DeleteOutlined
} from '@ant-design/icons';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Parent Commodity',
    dataIndex: 'parentCategory',
    key: 'parentCategory',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];

const generateFilterParams = (filter: RequestFilter): RequestFilter => ({
  limit: filter.limit || undefined,
  page: filter.page || undefined,
  name: filter.name || undefined,
  fromCreatedAt: filter.fromCreatedAt || undefined,
  toCreatedAt: filter.toCreatedAt || undefined,
});

function Commodities() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [commodities, setCommodities] = useState<HttpRes<CommodityRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [colorCode, setColorCode] = useState<string>('#000000');
  const [edit, setEdit] = useState<CommodityRes>({
    _id: '',
    colorCode: '#000000',
    createdAt: '',
    name: '',
    parent: {
      _id: '',
      name: ''
    }
  });
  const [filter, setFilter] = useState<RequestFilter>({
    limit: 5,
    page: 1,
    name: '',
    fromCreatedAt: '',
    toCreatedAt: '',
  });

  const changeFilter = (changedFilter: Partial<RequestFilter>) => {
    if (!changedFilter.page) {
      setFilter({
        ...filter,
        page: 1
      });
    }
    setFilter((filter) => ({
      ...filter,
      ...changedFilter
    }));
  };

  const mapCategories = () => commodities.items.map(commodity => (
    {
      key: commodity._id,
      name: (
        <b>{commodity.name}</b>
      ),
      parentCategory: (
        <span>{commodity.parent?.name || '-'}</span>
      ),
      createdAt: (
        <span>{formatDate(commodity.createdAt)}</span>
      ),
      actions: (
        <>
          <Row className="ant-employed" gutter={[8, 0]} style={{justifyContent: 'flex-end'}}>
            <Col>
              <Tooltip title="Edit">
                <a href="#form">
                  <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => {
                    setEdit(
                      {
                        _id: commodity._id,
                        colorCode: commodity?.colorCode || '',
                        createdAt: commodity.createdAt,
                        name: commodity.name,
                        parent: { _id: commodity.parent?._id || '',
                          name: commodity.parent?.name || '' }
                      }),
                    form.setFieldValue('name', commodity.name);
                    form.setFieldValue('parent', commodity.parent._id);
                    form.setFieldValue('colorCode', commodity.colorCode);
                    setColorCode(commodity.colorCode);
                  }}
                  />
                </a>
              </Tooltip>
            </Col>
            <Col>
              <Popconfirm
                placement="bottomRight"
                title='Delete the Commodity'
                description='Are you sure to delete this Commodity?'
                onConfirm={() => {
                  deleteCommodity(commodity._id)
                    .then(() => {
                      handleGetCommodities();
                      if (edit._id === commodity._id) {
                        handleResetForm();
                      }
                    });
                }}
                okText="Delete"
                cancelText="Cancel"
              >
                <Tooltip title="Delete">
                  <Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        </>
      ),
    }
  ));

  const handleGetCommodities = () => {
    const filterParams: RequestFilter = generateFilterParams(filter);

    getCommodities(filterParams)
      .then((body: HttpRes<CommodityRes>) => setCommodities(body))
      .finally(() => setIsLoading(false));
  };

  const handleResetForm = () => {
    setEdit({
      _id: '',
      colorCode: '',
      createdAt: '',
      name: '',
      parent: {
        _id: '',
        name: ''
      }
    });
    setColorCode('#000000');
    form.setFieldValue('name', '');
    form.setFieldValue('parent', null);
    form.setFieldValue('colorCode', '#000000');
  };

  const handleSubmit = () => {
    if (edit._id !== '') {
      updateCommodity({...form.getFieldsValue(), _id: edit._id })
        .then(() => {
          handleGetCommodities();
          handleResetForm();
          messageApi.open({
            type: 'success',
            content: 'Commodity successfully updated',
          });
        });
    } else {
      createCommodity(form.getFieldsValue())
        .then(() => {
          handleGetCommodities();
          handleResetForm();
          messageApi.open({
            type: 'success',
            content: 'Commodity successfully created',
          });
        });
    }
  };

  const handleSetColorCode = (e: string) => {
    setColorCode(e);
    form.setFieldValue('colorCode', e);
  };

  const handleSearchWithDebounce = useMemo(() => {
    return debounce(
      (e) => changeFilter({ name: e.target.value }),
      500
    );
  }, []);

  useEffect(() => {
    handleGetCommodities();
  }, [filter]);

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle('Commodities')}</title>
      </Helmet>
      {contextHolder}
      <HeaderBreadcrumb items={['Commodities']} />
      <div className="tabled">
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <Form name='Commodity' form={form} onFinish={handleSubmit} layout='vertical' id='form'>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Create Commodity"
                extra={
                  <Button type="primary" htmlType='submit' shape="default" icon={<SaveOutlined />} size="large" style={{width: '120px'}}>
                    {edit._id !== '' ? 'Save' : 'Create'}
                  </Button>
                }
              >
                <div className="table-responsive">
                  <Row gutter={[24, 0]}>
                    <Col xs={24} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <Form.Item
                        name='name'
                        rules={[{ required: true, message: 'Please enter commodity name' }, { min: 3, message: 'Commodity name must be longer than 2 characters' }]}
                        label='Commodity Name'
                        style={{width: '100%'}}
                      >
                        <Input placeholder="Commodity Name" size='large' />
                      </Form.Item>
                    </Col>
                    <Col xs={20} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <Form.Item
                        name='parent'
                        label='Parent'
                        style={{width: '100%'}}
                      >
                        <Select
                          size='large'
                          style={{width: '100%'}}
                          placeholder='Select a Parent'
                          fieldNames={{ value: '_id', label: 'name' }}
                          options={commodities.items?.sort()}
                          filterOption={(input: string, option: any) =>
                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          showSearch
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={4} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <Form.Item
                        name='colorCode'
                        rules={[{ required: true, message: 'Please enter color code' }, { min: 3, message: 'Color code must be longer than 2 characters' }]}
                        initialValue={'#000000'}
                        label='Color Code'
                        style={{width: '100%', textAlign: 'center'}}
                        className="color-picker"
                      >
                        <input type="color" id='colorCode' className='color-picker' value={colorCode} onChange={(e) => handleSetColorCode(e.target.value)} />
                        <input type="text" name="colorCode" value={colorCode} onChange={(e) => {setColorCode(e.target.value), form.setFieldValue('colorCode', e.target.value);}} className="color-text" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Form>
          </Col>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="List of Commodities"
              extra={
                <Row className='table-filter'>
                  <Col xs={12} style={{marginRight: '10px'}}>
                    <Search placeholder="Search in commodity name" onChange={handleSearchWithDebounce} />
                  </Col>

                  <Col xs={9}>
                    <DatePicker.RangePicker
                      onChange={(e: any) => {
                        changeFilter(getDateFromDatePicker(e));
                      }}
                    />
                  </Col>
                </Row>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={mapCategories()}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Card style={{marginTop: '16px', justifyContent: 'center', display:'flex'}}>
        <Pagination total={commodities.totalItemCount} 
          current={filter.page} 
          onChange={(e) => changeFilter({ page: e })} 
          pageSize={filter.limit} 
          showTotal={(total) => `Total ${total} items`} 
          onShowSizeChange={(e, selectedNumber) => {changeFilter({ limit: selectedNumber});}} 
          defaultPageSize={20}
          showSizeChanger />
      </Card>
    </>
  );
}

export default Commodities;
