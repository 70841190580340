import {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Descriptions, Row, Select, Table, Tooltip} from 'antd';
import Card from 'antd/es/card/Card';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import createPageTitle from '../../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';
import {getLoginStatistics, getRankingActiveUsers, getUserAppUsage} from '../../../../services/dashboardService';
import ApexChart from './components/ApexChart';
import {ChartData} from '../../../../core/types/ChartData';
import {ChartRequestFilter} from '../../../../core/types/ChartRequestFilter';
import getDateFromDatePicker from '../../../../utils/getDateFromDatePicker';
import {getAllSubscribers} from '../../../../services/subscriberService';
import {AllSubscriberItem} from '../../../../core/dtos/subscriberRes';
import {UserAppUsageRes} from '../../../../core/dtos/userAppUsageRes';
import formatDate from '../../../../utils/formatDate';
import {EyeOutlined} from '@ant-design/icons';
import {RankingActiveUserRes} from '../../../../core/dtos/rankingActiveUserRes';
import {Link} from 'react-router-dom';
import {RankingActiveUsersRequestFilter} from '../../../../core/types/RankingActiveUsersRequestFilter';

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Bookmark Post Count',
    dataIndex: 'bookmarkPostCount',
    key: 'bookmarkPostCount',
  },
  {
    title: 'List Post Count',
    dataIndex: 'listPostCount',
    key: 'listPostCount',
  },
  {
    title: 'Share Post Count',
    dataIndex: 'sharePostCount',
    key: 'sharePostCount',
  },
  {
    title: 'Search Post Count',
    dataIndex: 'searchPostCount',
    key: 'searchPostCount',
  },
  {
    title: 'Total Usage Count',
    dataIndex: 'totalUsageCount',
    key: 'totalUsageCount',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];

export default function Dashboard() {
  const [chartTotalLogins, setChartTotalLogins] = useState<ChartData[]>([]);
  const [uniqueLogins, setUniqueLogins] = useState<ChartData[]>([]);
  const [filter, setFilter] = useState<ChartRequestFilter>({
    from: undefined,
    to: undefined
  });
  const [subscribers, setSubscribers] = useState<AllSubscriberItem[]>([]);
  const [selectedSubscriberAppUsageInfos, setSelectedSubscriberAppUsageInfos] = useState<UserAppUsageRes|null>(null);
  const [rankingActiveUsers, setRankingActiveUsers] = useState<RankingActiveUserRes[]>([]);
  const [appUsageRequestFilter, setAppUsageRequestFilter] = useState<RankingActiveUsersRequestFilter>({
    date: undefined
  });
  const [selectedAppUsageUserId, setSelectedAppUsageUserId] = useState<string>('');

  const mapRankingActiveUsers = () => rankingActiveUsers.map(user => (
    {
      key: user._id,
      username: (
        <b>{user.subscriber?.username}</b>
      ),
      name: (
        <b>{user.subscriber?.name}</b>
      ),
      date: (
        <span>{formatDate(user.date)}</span>
      ),
      bookmarkPostCount: (
        <span>{user.bookmarkPostCount}</span>
      ),
      listPostCount: (
        <span>{user.listPostCount}</span>
      ),
      sharePostCount: (
        <span>{user.sharePostCount}</span>
      ),
      searchPostCount: (
        <span>{user.searchPostCount}</span>
      ),
      totalUsageCount: (
        <span>{user.totalUsageCount}</span>
      ),
      actions: (
        <Row className="ant-employed table-actions" gutter={[8, 0]}>
          <Col>
            <Tooltip title="Show More">
              <Link to={`/subscribers/${user.subscriber?._id}`}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      ),
    }
  ));

  useEffect(() => {
    Promise.all([
      getAllSubscribers(),
      getRankingActiveUsers()
    ])
      .then(([subscribers, rankingActiveUsers]) => {
        setSubscribers(subscribers);
        setRankingActiveUsers(rankingActiveUsers);
      });
  }, []);

  useEffect(() => {
    getLoginStatistics(filter).then(body => {
      setChartTotalLogins(body.totalLogins);
      setUniqueLogins(body.uniqueLogins);
    });
  }, [filter]);
  
  const handleGetRankingActiveUsers = (params?: { date?: string; }) => {
    getRankingActiveUsers(params)
      .then((res) => {
        setRankingActiveUsers(res);
      });
  };

  useEffect(() => {
    if (selectedAppUsageUserId) {
      getUserAppUsage(selectedAppUsageUserId, appUsageRequestFilter)
        .then((res) => {
          setSelectedSubscriberAppUsageInfos(res);
        });
    }
  }, [appUsageRequestFilter, selectedAppUsageUserId]);

  return (
    <>
      <Helmet>
        <title>{createPageTitle('Dashboard')}</title>
      </Helmet>
      <HeaderBreadcrumb items={['Dashboard']} />
      <div className="layout-content" style={{display: 'grid', rowGap: '24px', gridTemplateColumns: '100%', margin: '20px 0'}}>
        <Card>
          <Row>
            <Col xs={12}>
              <h2 style={{margin: '0'}}>Graphics</h2>
            </Col>
            <Col xs={12} style={{textAlign: 'right'}}>
              <DatePicker.RangePicker
                onChange={(e: any) => setFilter(getDateFromDatePicker(e, ['from', 'to']))}
              />
            </Col>
          </Row>
        </Card>
        <Card title="Total Logins">
          <ApexChart chartData={chartTotalLogins}></ApexChart>
        </Card>
        <Card title="Unique Logins">
          <ApexChart chartData={uniqueLogins}></ApexChart>
        </Card>
        <Card
          title="Ranking Active Users"
          extra={
            <Row className="table-filter" gutter={[18, 0]}>
              <Col xs={12}>
                <DatePicker
                  placeholder="Date"
                  style={{width: '100%'}}
                  onChange={(e, date) => handleGetRankingActiveUsers({date: date || undefined})}
                />
              </Col>
            </Row>
          }>
          <Table
            columns={columns}
            dataSource={mapRankingActiveUsers()}
            pagination={false}
            className="ant-border-space"
          />
        </Card>
        <Card
          title="App Usage of Subscriber"
          extra={
            <Row className="table-filter" gutter={[18, 0]}>
              <Col xs={12}>
                <DatePicker
                  placeholder="Date"
                  style={{width: '100%'}}
                  onChange={(e, date) => setAppUsageRequestFilter({date: date || undefined})}
                />
              </Col>
              <Col xs={12}>
                <Select
                  allowClear
                  placeholder='Select a Subscriber'
                  fieldNames={{ value: '_id', label: 'username' }}
                  options={subscribers?.sort()}
                  showSearch={true}
                  onChange={(e) => setSelectedAppUsageUserId(e)}
                  filterOption={(input: string, option: any) =>
                    (option?.username ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onClear={() => {
                    setSelectedAppUsageUserId('');
                    setSelectedSubscriberAppUsageInfos(null);
                  }}
                  style={{width: '100%', textAlign: 'left'}}
                />
              </Col>
            </Row>
          }>
          {selectedSubscriberAppUsageInfos ? (
            <Descriptions>
              <Descriptions.Item label="Name">{selectedSubscriberAppUsageInfos.subscriber?.name}</Descriptions.Item>
              <Descriptions.Item label="Username">{selectedSubscriberAppUsageInfos.subscriber?.username}</Descriptions.Item>
              <Descriptions.Item label="Android Login Count">{selectedSubscriberAppUsageInfos.androidLoginCount}</Descriptions.Item>
              <Descriptions.Item label="API Login Count">{selectedSubscriberAppUsageInfos.apiLoginCount}</Descriptions.Item>
              <Descriptions.Item label="Bookmark Post Count">{selectedSubscriberAppUsageInfos.bookmarkPostCount}</Descriptions.Item>
              <Descriptions.Item label="iOS Login Count">{selectedSubscriberAppUsageInfos.iosLoginCount}</Descriptions.Item>
              <Descriptions.Item label="List Post Count">{selectedSubscriberAppUsageInfos.listPostCount}</Descriptions.Item>
              <Descriptions.Item label="Mobile Login Count">{selectedSubscriberAppUsageInfos.mobileLoginCount}</Descriptions.Item>
              <Descriptions.Item label="Search Post Count">{selectedSubscriberAppUsageInfos.searchPostCount}</Descriptions.Item>
              <Descriptions.Item label="Share Post Count">{selectedSubscriberAppUsageInfos.sharePostCount}</Descriptions.Item>
              <Descriptions.Item label="Total Login Count">{selectedSubscriberAppUsageInfos.totalLoginCount}</Descriptions.Item>
              <Descriptions.Item label="Total Usage Count">{selectedSubscriberAppUsageInfos.totalUsageCount}</Descriptions.Item>
              <Descriptions.Item label="Web Login Count">{selectedSubscriberAppUsageInfos.webLoginCount}</Descriptions.Item>
            </Descriptions>
          ) : selectedAppUsageUserId && !selectedSubscriberAppUsageInfos ? (
            <span>No data found for this subscriber</span>
          ) : (
            <span>Please select a subscriber</span>
          )}
        </Card>
      </div>
    </>
  );
}
