import {Button, Col, Divider, Form, Input, message, Popconfirm, Row, Select, Switch, Tooltip} from 'antd';
import Card from 'antd/es/card/Card';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {createPost, getPost, updatePost} from '../../../../services/postService';
import {PostDetailRes} from '../../../../core/dtos/postDetailRes';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import {PostStatus} from '../../../../core/enums/PostStatus';
import {CategoryRes} from '../../../../core/dtos/categoryRes';
import {getAllCategories} from '../../../../services/categoryService';
import {getAllCountries} from '../../../../services/countryService';
import {CountryRes} from '../../../../core/dtos/countryRes';
import {getAllCommodities} from '../../../../services/commodityService';
import {CommodityRes} from '../../../../core/dtos/commodityRes';
import {getAllSources} from '../../../../services/sourceService';
import {SourceRes} from '../../../../core/dtos/sourceRes';
import Loader from '../../../../components/Loader';
import {PostSource} from '../../../../core/dtos/postReq';
import createPageTitle from '../../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';
import ModalPreview from './components/ModalPreview';
import textEditorOptions from '../../../../core/consts/textEditorOptions';
import ReactQuill from 'react-quill';
import ModalCreateSource from '../../../../components/Modals/ModalCreateSource';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';

export default function Post() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [post, setPost] = useState<PostDetailRes>({
    headline: '',
    body: '',
    story: '',
    sources: [
      {
        _id: '',
        url: '',
        name: ''
      }
    ],
    categories: [],
    countries: [],
    commodities: [],
    status: PostStatus.PUBLISHED,
    _id: '',
    createdAt: '',
    publishedAt: '',
    author: {
      _id: '',
      name: ''
    }
  });
  const [sources, setSources] = useState<SourceRes[]>([]);
  const [categories, setCategories] = useState<CategoryRes[]>([]);
  const [countries, setCountries] = useState<CountryRes[]>([]);
  const [commodities, setCommodities] = useState<CommodityRes[]>([]);
  const isEdit = !!id;
  const [isLoading, setIsLoading] = useState(true);
  const [isPostPreviewContentOpen, setIsPostPreviewContentOpen] = useState<boolean>(false);
  const [isCreateSourceModalOpen, setIsCreateSourceModalOpen] = useState<boolean>(false);

  useEffect(() => {
    Promise.all([
      getAllSources(),
      getAllCategories(),
      getAllCountries(),
      getAllCommodities(),
    ])
      .then(([sources, categories, countries, commodities]: [SourceRes[], CategoryRes[], CountryRes[], CommodityRes[]]) => {
        setSources(sources);
        setCategories(categories);
        setCountries(countries);
        setCommodities(commodities);
      });

    if (isEdit)
      getPost(id)
        .then((body: PostDetailRes) => {
          form.setFieldsValue({
            ...body,
            sources: body.sources.map((source: PostSource) => ({_id: source._id, url: source?.url || ''})),
            categories: body.categories.map(item => item._id),
            commodities: body.commodities.map(item => item._id),
            countries: body.countries.map(item => item._id),
          });
          setPost({...body, status: body?.status || PostStatus.PUBLISHED});
        })
        .finally(() => {
          setIsLoading(false); 
        });
    else {
      setIsLoading(false);
      form.setFieldValue('sources', [
        {
          _id: undefined,
          url: ''
        }
      ]);
      form.setFieldValue('status', PostStatus.PUBLISHED);
    }
  }, []);

  const handleSubmit = () => {
    if (isEdit) {
      updatePost({
        ...form.getFieldsValue(),
        status: form.getFieldValue('status') !== post.status
          ? form.getFieldValue('status')
          : undefined,
        _id: post._id
      })
        .then(() => {
          navigate('/posts');
          messageApi.open({
            type: 'success',
            content: 'Post successfully updated',
          });
        }
        ).catch((err) => {
          messageApi.open({
            type: 'error',
            content: 'There is a problem',
          });
        });
    } else {
      createPost(form.getFieldsValue())
        .then(() => {
          navigate('/posts');
          messageApi.open({
            type: 'success',
            content: 'Post successfully created',
          });
        }
        ).catch(() => {
          messageApi.open({
            type: 'error',
            content: 'There is a problem',
          });
        });
    }
  };

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle(post?.headline || 'Create Post')}</title>
      </Helmet>
      {contextHolder}
      <HeaderBreadcrumb items={['Posts', 'Create/Edit Post']} />
      <Card title={isEdit ? 'Edit Post' : 'Create Post'}>
        <Form name='Post' form={form} onFinish={handleSubmit} layout='vertical'>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Row gutter={[24, 0]}>
                <Col xs={24}>
                  <Form.List
                    name="sources"
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 1) {
                            return Promise.reject(new Error('Please select at least 1 source'));
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            required={false}
                            style={{width: '100%', marginBottom: '0'}}
                            key={field.key}
                          >
                            <Row gutter={[16, 0]}>
                              <Col xs={12}>
                                <Form.Item
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select source name or delete this field.',
                                    },
                                  ]}
                                  name={[field.name, '_id']}
                                  label={
                                    index === 0 ? (
                                      <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                        <span>Source</span>
                                        <Tooltip title="Create Source">
                                          <Button size="small" type="dashed" shape="round" icon={<PlusCircleOutlined />} onClick={() => setIsCreateSourceModalOpen(true)}>
                                          New
                                          </Button>
                                        </Tooltip>
                                      </div>
                                    ) : ''
                                  }
                                >
                                  <Select
                                    size='large'
                                    showSearch={true}
                                    filterOption={(input: string, option: any) =>
                                      (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{width: '100%'}}
                                    allowClear
                                    placeholder='Select a source'
                                    fieldNames={{ value: '_id', label: 'name' }}
                                    options={sources?.sort()}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={fields.length > 1 ? 11 : 12}>
                                <Form.Item
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  name={[field.name, 'url']}
                                  label={index === 0 ? 'Source Url' : ''}
                                >
                                  <Input placeholder="Source Url" size='large' />
                                </Form.Item>
                              </Col>
                              <Col xs={1} style={{marginTop: (index === 0 && fields.length > 1) ? '30px' : '3px'}}>
                                {fields.length > 1 ? (
                                  
                                  <Popconfirm
                                    placement="bottomRight"
                                    title='Delete the Source'
                                    description='Are you sure to delete this source?'
                                    onConfirm={() => remove(field.name)}
                                    okText="Delete"
                                    cancelText="Cancel"
                                  >
                                    <Tooltip title="Delete Source">
                                      <Button 
                                        danger
                                        type="primary" 
                                        shape="circle" 
                                        icon={<DeleteOutlined />} />
                                    </Tooltip>
                                  </Popconfirm>
                                
                                ) : null}
                              </Col>
                            </Row>
                            
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '100%' }}
                            icon={<PlusOutlined />}
                          >
                          Add field
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Form.Item
                name='headline'
                rules={[{ required: true, message: 'Please enter headline' }, { min: 3, message: 'Headline must be longer than 2 characters' }]}
                label='Headline'
              >
                <Input placeholder="Headline" size='large' />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name='body'
                rules={[{ required: true, message: 'Please enter content' }]}
                label='Content'
              >
                <ReactQuill
                  theme="snow"
                  formats={textEditorOptions.formats}
                  modules={textEditorOptions.modules} />
              </Form.Item>

              <Button type="primary" size="small" style={{marginLeft: 'auto', display: 'flex', marginTop: '-10px'}} onClick={() => setIsPostPreviewContentOpen(form.getFieldsValue())}>
                Preview
              </Button>
            </Col>
            <Col xs={12}>
              <Form.Item
                name='categories'
                rules={[{ required: true, message: 'Please select at least 1 category' }]}
                label='Categories'
              >
                <Select
                  mode="multiple"
                  allowClear
                  size='large'
                  style={{ width: '100%' }}
                  placeholder="Select the associated categories"
                  fieldNames={{ value: '_id', label: 'name' }}
                  filterOption={(input: string, option: any) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={categories?.sort()}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name='commodities'
                rules={[{ required: true, message: 'Please select at least 1 commodity' }]}
                label='Commodities'
              >
                <Select
                  mode="multiple"
                  allowClear
                  size='large'
                  style={{ width: '100%' }}
                  placeholder="Select this relevant commodities"
                  fieldNames={{ value: '_id', label: 'name' }}
                  filterOption={(input: string, option: any) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={commodities?.sort()}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name='countries'
                rules={[{ required: true, message: 'Please select at least 1 country' }]}
                label='Countries'
              >
                <Select
                  mode="multiple"
                  allowClear
                  size='large'
                  style={{ width: '100%' }}
                  placeholder="Select the relevant countries"
                  fieldNames={{ value: '_id', label: 'name' }}
                  filterOption={(input: string, option: any) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={countries?.sort()}
                />
              </Form.Item>
            </Col>
            <Col xs={24} style={{display: 'flex', alignItems:'center', gap:'8px'}}>
              <Form.Item
                name='status'
                label='Status'
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  id="is-active"
                  defaultChecked={form.getFieldValue('status') === PostStatus.PUBLISHED}
                  onChange={(e) => form.setFieldValue('status', !e ? PostStatus.DRAFT : PostStatus.PUBLISHED)}
                />
                <label htmlFor='is-active' style={{marginLeft: '8px'}}>
                  <b>Published</b>
                </label>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider />
            </Col>
            <Col xs={24}>
              <Form.Item
                name='story'
                label='Story'
              >
                <ReactQuill
                  theme="snow"
                  formats={textEditorOptions.formats}
                  modules={textEditorOptions.modules} />
              </Form.Item>
            </Col>
            <Col xs={24} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '24px'}}>
              <Button type="primary" shape="default" icon={<ArrowLeftOutlined />} size="large" danger style={{width: '200px'}} onClick={() => navigate(-1)}>
            Back
              </Button>
              <Button type="primary" htmlType='submit' shape="default" icon={isEdit ? <SaveOutlined /> : <PlusCircleOutlined />} size="large" style={{width: '200px'}}>
                {isEdit ? 'Save' : 'Create'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      <ModalCreateSource isOpen={isCreateSourceModalOpen} setIsOpen={setIsCreateSourceModalOpen} setSources={setSources} />

      <ModalPreview
        isOpen={isPostPreviewContentOpen}
        setIsOpen={setIsPostPreviewContentOpen}
        postData={{
          commodities: form.getFieldValue('commodities'),
          countries: form.getFieldValue('countries'),
          sources: form.getFieldValue('sources'),
          headline: form.getFieldValue('headline'),
          body: form.getFieldValue('body')
        }}
        commodities={commodities}
        countries={countries}
        sources={sources} />
    </>
  );
}
