import {Dispatch} from 'react';
import {Col, DatePicker, Form, Radio, Row, Select} from 'antd';
import {CommodityRes} from '../../../../../core/dtos/commodityRes';
import {PostStatus} from '../../../../../core/enums/PostStatus';
import {PostRequestFilter} from '../../../../../core/types/PostRequestFilter';
import {SourceRes} from '../../../../../core/dtos/sourceRes';
import {CategoryRes} from '../../../../../core/dtos/categoryRes';
import {CountryRes} from '../../../../../core/dtos/countryRes';
import ModalFilterLayout from '../../../../../components/Modals/ModalFilterLayout';

export default function ModalPostsFilter({
  isOpen,
  setIsOpen,
  filter,
  changeFilter,
  form,
  sources,
  commodities,
  countries,
  categories
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  filter: PostRequestFilter;
  changeFilter: (changedFilter: Partial<PostRequestFilter>) => void;
  form: any;
  sources: SourceRes[];
  commodities: CommodityRes[]
  countries: CountryRes[];
  categories: CategoryRes[];
}) {
  return (
    <ModalFilterLayout header='Filter Posts' isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => changeFilter(form.getFieldsValue())}>
      <Form name='Post' form={form} layout='vertical'>
        <Row gutter={[18, 0]}>
          <Col xs={24} style={{marginTop: '16px'}}>
            <Form.Item
              name='sources'
              label='Sources'
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Select source(s)"
                size='large'
                fieldNames={{ value: '_id', label: 'name' }}
                filterOption={(input: string, option: any) =>
                  (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={sources?.sort()}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name='commodities'
              label='Commodities'
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Select commodities"
                size='large'
                fieldNames={{ value: '_id', label: 'name' }}
                filterOption={(input: string, option: any) =>
                  (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={commodities?.sort()}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name='categories'
              label='Categories'
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Select categories"
                size='large'
                fieldNames={{ value: '_id', label: 'name' }}
                filterOption={(input: string, option: any) =>
                  (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={categories?.sort()}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name='countries'
              label='Countries'
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Select countries"
                size='large'
                fieldNames={{ value: '_id', label: 'name' }}
                filterOption={(input: string, option: any) =>
                  (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={countries?.sort()}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name='status'
              label='Status'
            >
              <Radio.Group
                value={filter.status} >
                <Radio.Button value={PostStatus.ALL}>All</Radio.Button>
                <Radio.Button value={PostStatus.PUBLISHED}>Published</Radio.Button>
                <Radio.Button value={PostStatus.DRAFT}>Drafts</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name='publishedAt'
              label='Date'
            >
              <DatePicker.RangePicker/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalFilterLayout>
  );
}
