import React, {Dispatch, SetStateAction} from 'react';
import Post from './Post';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {IMailGeneratorDragAndDrop, MailGeneratorPost} from '../../../../../core/types/MailGeneratorDragAndDrop';
import {DragOutlined, EditOutlined, EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';
import {Button, Input} from 'antd';

const Column = (
  {
    posts,
    column,
    index,
    dragAndDropEditItemId,
    setDragAndDropEditItemId,
    dragAndDropItems,
    setDragAndDropItems
  }:
      {
        posts: MailGeneratorPost[];
        column: IMailGeneratorDragAndDrop;
        index: number;
        dragAndDropEditItemId: string|null;
        setDragAndDropEditItemId: Dispatch<SetStateAction<string|null>>;
        dragAndDropItems: IMailGeneratorDragAndDrop[];
        setDragAndDropItems: Dispatch<SetStateAction<IMailGeneratorDragAndDrop[]>>;
      }) => {

  const handleChangeColumnTitle = (newText: string) => {
    const cloneDragAndDropItems = [...dragAndDropItems];
    cloneDragAndDropItems[index].title = newText;

    setDragAndDropItems(cloneDragAndDropItems);
  };

  const handleChangeColumnVisibility = () => {
    const cloneDragAndDropItems = [...dragAndDropItems];
    cloneDragAndDropItems[index].isVisible = !column.isVisible;

    setDragAndDropItems(cloneDragAndDropItems);
  };

  return (
    <Draggable draggableId={column.id} index={index}>
      {(provided) => (
        <div
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="list-item"
        >
          <div className="column-header">
            <div className="column-header-item column-left">
              <DragOutlined style={{fontSize: '20px'}} />
              {dragAndDropEditItemId === column.id ? (
                <Input
                  className="column-edit-input"
                  placeholder="Country title"
                  bordered={false}
                  value={column.title}
                  onChange={event => handleChangeColumnTitle(event.target.value)}
                  style={{width: '100%'}} />
              ) : (
                <span className="title">{column.title}</span>
              )}
            </div>
            <div className="column-header-item column-right">
              <Button
                type={'ghost'}
                onClick={() => dragAndDropEditItemId !== column.id ? (
                  setDragAndDropEditItemId(column.id)
                ) : (
                  setDragAndDropEditItemId(null)
                )}
              >
                <EditOutlined style={{fontSize: '20px'}} />
              </Button>
              <Button
                type={'ghost'}
                onClick={handleChangeColumnVisibility}
              >
                {!column.isVisible ? (
                  <EyeInvisibleOutlined
                    className="disabled-item-btn-icon"
                    style={{fontSize: '20px'}} />
                ) : (
                  <EyeOutlined
                    style={{fontSize: '20px'}} />
                )}
              </Button>
            </div>
          </div>
          <Droppable droppableId={column.id} type="post">
            {(provided, snapshot) => (
              <div
                className="post-list"
                style={{ background: snapshot.isDraggingOver ? '#e5e5e5' : 'white' }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {posts.map((post, postIndex) => (
                  <Post
                    key={post._id}
                    columnIndex={index}
                    postIndex={postIndex}
                    post={post}
                    dragAndDropEditItemId={dragAndDropEditItemId}
                    setDragAndDropEditItemId={setDragAndDropEditItemId}
                    dragAndDropItems={dragAndDropItems}
                    setDragAndDropItems={setDragAndDropItems} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default Column;
