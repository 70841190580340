import React, { Dispatch, SetStateAction, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {DragOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import {Button, Input} from 'antd';
import {IMailGeneratorDragAndDrop, MailGeneratorPost} from '../../../../../core/types/MailGeneratorDragAndDrop';
import ModalPreview from '../../Posts/components/ModalPreview';

const Post = (
  {
    columnIndex,
    postIndex,
    post,
    dragAndDropEditItemId,
    setDragAndDropEditItemId,
    dragAndDropItems,
    setDragAndDropItems
  }: {
      columnIndex: number;
      postIndex: number;
      post: MailGeneratorPost;
      dragAndDropEditItemId: string|null;
      setDragAndDropEditItemId: Dispatch<SetStateAction<string|null>>;
      dragAndDropItems: IMailGeneratorDragAndDrop[];
      setDragAndDropItems: Dispatch<SetStateAction<IMailGeneratorDragAndDrop[]>>
    }) => {

  const [isPostPreviewContentOpen, setIsPostPreviewContentOpen] = useState<boolean>(false);

  const handleChangePostTitle = (newText: string) => {
    const cloneDragAndDropItems = [...dragAndDropItems];
    cloneDragAndDropItems[columnIndex].posts[postIndex].fullTitle = newText;

    setDragAndDropItems(cloneDragAndDropItems);
  };

  const handleChangePostVisibility = () => {
    const cloneDragAndDropItems = [...dragAndDropItems];
    cloneDragAndDropItems[columnIndex].posts[postIndex].isVisible = !post.isVisible;

    setDragAndDropItems(cloneDragAndDropItems);
  };

  return (
    <>
      <Draggable draggableId={post._id} index={postIndex}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            className={'list-item post-list-item' + (!post.isVisible ? ' invisible' : '')}
            style={{
              background: snapshot.isDragging ? '#ffc65f' : 'white',
              ...provided.draggableProps.style
            }}
          >
            <div className="column-header">
              <div className="column-header-item column-left">
                <DragOutlined style={{fontSize: '20px'}} />

                {dragAndDropEditItemId === post._id ? (
                  <Input
                    placeholder="Country title"
                    bordered={false}
                    value={post.fullTitle}
                    onChange={event => handleChangePostTitle(event.target.value)}
                    style={{width: '100%'}} />
                ) : (
                  <span className="title" onClick={() => setIsPostPreviewContentOpen(true)}>{post.fullTitle}</span>
                )}
              </div>
              <div className="column-header-item column-right">
                <Button
                  type={'ghost'}
                  onClick={() => dragAndDropEditItemId !== post._id ? (
                    setDragAndDropEditItemId(post._id)
                  ) : (
                    setDragAndDropEditItemId(null)
                  )}
                >
                  <EditOutlined style={{fontSize: '20px'}} />
                </Button>
                <Button
                  type={'ghost'}
                  onClick={handleChangePostVisibility}
                >
                  {!post.isVisible ? (
                    <EyeInvisibleOutlined
                      className="disabled-item-btn-icon"
                      style={{fontSize: '20px'}} />
                  ) : (
                    <EyeOutlined
                      style={{fontSize: '20px'}} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Draggable>

      <ModalPreview
        isOpen={isPostPreviewContentOpen}
        setIsOpen={setIsPostPreviewContentOpen}
        postData={{
          commodities: post.commodities,
          countries: post.countries,
          sources: post.sources,
          headline: post.headline,
          body: post.body
        }}
        commodities={[]}
        countries={[]}
        sources={[]} />

    </>
  );
};

export default Post;
