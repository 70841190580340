import {useEffect, useState} from 'react';
import Pagination from 'antd/lib/pagination';
import {Helmet} from 'react-helmet';
import { Button, Card, Checkbox, Col, Modal, Row, Table, Tooltip } from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import {HttpRes} from '../../../core/dtos/httpRes';
import {ContactRes} from '../../../core/dtos/contactRes';
import {RequestFilter} from '../../../core/types/RequestFilter';
import formatDate from '../../../utils/formatDate';
import Loader from '../../../components/Loader';
import createPageTitle from '../../../utils/createPageTitle';
import HeaderBreadcrumb from '../../../components/Breadcrumb';
import { getContact, markAsRead } from '../../../services/contactService';
import {useSearchParams} from 'react-router-dom';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Comment',
    key: 'comment',
    dataIndex: 'comment',
  },
  {
    title: 'Created At',
    key: 'createdAt',
    dataIndex: 'createdAt',
  },
  {
    title: 'Mark As Read',
    key: 'markAsRead',
    dataIndex: 'markAsRead',
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
  }
];

function Contact() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [contact, setContact] = useState<HttpRes<ContactRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });
  const [filter, setFilter] = useState<RequestFilter>({
    page: 1,
    limit: 20,
    sortBy: 'createdAt',
    orderBy: 'desc'
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeContactDetails, setActiveContactDetails] = useState<ContactRes|null>();

  const mapContact = () => contact.items.map(message => (
    {
      key: message._id,
      disabled: !message.isRead,
      name: <b>{message.name}</b>,
      email: <a href={`mailto:${message.email}`}><b>{message.email}</b></a>,
      comment: <span>{message.comment.slice(0, 40) + (message.comment.length >= 40 ? '...' : '')}</span>,
      createdAt: <span>{formatDate(message.createdAt)}</span>,
      markAsRead: (
        <Row className="ant-employed" style={{ marginLeft: '35px' }}>
          <Col>
            <Checkbox checked={message.isRead} onChange={(e) => onChangeRead(message._id, e.target.checked) }></Checkbox>
          </Col>
        </Row>
      ),
      actions: (
        <Row className="ant-employed table-actions" gutter={[8, 0]}>
          <Col>
            <Tooltip title="Show More">
              <Button
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => setActiveContactDetails(message)} />
            </Tooltip>
          </Col>
        </Row>
      ),
    }
  ));

  const handleGetContact = () => {
    getContact(filter)
      .then((body: HttpRes<ContactRes>) => setContact(body))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleGetContact();
  }, [filter]);

  const changeFilter = (changedFilter: Partial<RequestFilter>) => {
    setFilter((filter) => ({
      ...filter,
      ...changedFilter
    }));
  };

  const onChangeRead = (id: string, isRead: boolean) => {
    setIsLoading(true);
    markAsRead(id, isRead)
      .then(() => {
        handleGetContact();
        searchParams.set('fetch', new Date().toISOString());
        setSearchParams(searchParams);
      })
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle('Contact')}</title>
      </Helmet>
      <div className='breadcrumb-wrapper'>
        <HeaderBreadcrumb items={['Contact']} />
      </div>
      <div className="table">
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="List of Contact">
              <div className="table-responsive">
                <Table
                  rowClassName={(record) => !record.disabled ? 'disabled-row' : ''}
                  columns={columns}
                  dataSource={mapContact()}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Card style={{marginTop: '16px', justifyContent: 'center', display:'flex'}}>
        <Pagination total={contact.totalItemCount}
          current={filter.page}
          onChange={(e) => changeFilter({ page: e })}
          pageSize={filter.limit}
          showTotal={(total) => `Total ${total} items`}
          onShowSizeChange={(e, selectedNumber) => changeFilter({ limit: selectedNumber })}
          defaultPageSize={20}
          showSizeChanger />
      </Card>

      <Modal
        centered
        title={`${activeContactDetails?.name}'s Message`}
        open={!!activeContactDetails}
        onOk={() => setActiveContactDetails(null)}
        onCancel={() => setActiveContactDetails(null)}
        width={800}
        style={{marginTop: '28px', marginBottom: '28px'}}
      >
        {activeContactDetails?.comment}
      </Modal>
    </>
  );
}

export default Contact;
