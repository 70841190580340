import { AxiosResponse } from 'axios';
import { USER } from '../core/consts/endpoints';
import { HttpRes } from '../core/dtos/httpRes';
import { UserReq } from '../core/dtos/userReq';
import { UserRes } from '../core/dtos/userRes';
import { RequestFilter } from '../core/types/RequestFilter';
import service from './axiosRequest';

export function getUsers(params?: RequestFilter): Promise<HttpRes<UserRes>> {
  return service.get<HttpRes<UserRes>>(USER, { params })
    .then((res: AxiosResponse<HttpRes<UserRes>>) => res.data);
}

export function getUser(id: string): Promise<UserRes> {
  return service.get<UserRes>(`${USER}/${id}`)
    .then((res: AxiosResponse<UserRes>) => res.data);
}

export function createUser(body: UserReq) {
  return service.post(USER, body)
    .then((res: AxiosResponse<UserRes>) => res.data);
}

export function updateUser(body: UserReq) {
  return service.patch(USER, body)
    .then((res: AxiosResponse<UserRes>) => res.data);
}

export function deleteUser(id: string) {
  return service.delete(`${USER}/${id}`);
}
