import {Button, Col, DatePicker, Divider, Form, Input, InputNumber, message, Row, Select, Switch} from 'antd';
import Card from 'antd/es/card/Card';
import {useEffect, useState} from 'react';
import {ArrowLeftOutlined, CheckOutlined, CloseOutlined, PlusCircleOutlined, SaveOutlined,} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import {getAllCountries} from '../../../../services/countryService';
import {CountryRes} from '../../../../core/dtos/countryRes';
import {getAllCommodities} from '../../../../services/commodityService';
import {CommodityRes} from '../../../../core/dtos/commodityRes';
import Loader from '../../../../components/Loader';
import {createSubscriber, getSubscriber, updateSubscriber} from '../../../../services/subscriberService';
import {SubscriberDetailRes} from '../../../../core/dtos/subscriberDetailRes';
import {SubscriberStatus} from '../../../../core/enums/SubscriberStatus';
import dayjs from 'dayjs';
import {Helmet} from 'react-helmet';
import createPageTitle from '../../../../utils/createPageTitle';
import {LoginRestrictionTypeEnum} from '../../../../core/enums/LoginRestrictionTypeEnum';

export default function Subscriber() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [subscriber, setSubscriber] = useState<SubscriberDetailRes>({
    _id: '',
    commodities: [],
    company: '',
    country: {
      _id: '',
      name: ''
    },
    createdAt: '',
    email: '',
    name: '',
    phone: '',
    postsBookmarked: [],
    status: SubscriberStatus.FREE_TRIAL,
    username: '',
    validTill: '',
    isBlocked: false,
    webAccess: true,
    mobileAccess: true,
    apiAccess: false,
    endDateOfPosts: '',
    startDateOfPosts: '',
    ipAddress: [],
    mobileCount: 0,
    webCount: 0,
    webRestrictionType: LoginRestrictionTypeEnum.MAX_COUNT,
  });
  const [countries, setCountries] = useState<CountryRes[]>([]);
  const [commodities, setCommodities] = useState<CommodityRes[]>([]);
  const isEdit = !!id;
  const [isLoading, setIsLoading] = useState(true);
  const [isValidTillActive, setIsValidTillActive] = useState(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [webRestrictionType, setWebRestrictionType] = useState<LoginRestrictionTypeEnum|undefined>(undefined);
  const [postsCutOffDate, setPostsCutOffDate] = useState<{startDateOfPosts: any; endDateOfPosts: any;}>({
    startDateOfPosts: '',
    endDateOfPosts: ''
  });

  useEffect(() => {
    Promise.all([
      getAllCountries(),
      getAllCommodities(),
    ])
      .then(([countries, commodities]: [CountryRes[], CommodityRes[]]) => {
        setCountries(countries);
        setCommodities(commodities);
      });

    if (isEdit)
      getSubscriber(id)
        .then((body: SubscriberDetailRes) => {
          form.setFieldsValue({
            ...body,
            country: body?.country?._id || '',
            commodities: body.commodities.map((commodity) => commodity._id),
            validTill: dayjs(body.validTill),
            webAccess: body.webAccess || false,
            mobileAccess: body.mobileAccess || false,
            apiAccess: body.apiAccess || false,
          });
          setSubscriber(body);
          setIsBlocked(body.isBlocked);
          setWebRestrictionType(body.webRestrictionType);
          setPostsCutOffDate({
            startDateOfPosts: dayjs(body.startDateOfPosts),
            endDateOfPosts: dayjs(body.endDateOfPosts)
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    else {
      form.resetFields();
      setIsLoading(false);
      form.setFieldValue('webAccess', true);
      form.setFieldValue('mobileAccess', true);
      form.setFieldValue('apiAccess', false);
    }
  }, []);

  const handleSubmit = () => {
    if (isEdit) {
      updateSubscriber({
        ...form.getFieldsValue(),
        _id: id,
        status: !isBlocked ? form.getFieldValue('status') : SubscriberStatus.FREE_TRIAL,
        validTill: !isBlocked ? form.getFieldValue('validTill') : undefined,
        isBlocked: isBlocked,
        unhashedPassword: form.getFieldValue('unhashedPassword') || undefined,
        confirmUnhashedPassword: form.getFieldValue('unhashedPassword') || undefined,
        startDateOfPosts: postsCutOffDate?.startDateOfPosts || undefined,
        endDateOfPosts: postsCutOffDate?.endDateOfPosts || undefined
      })
        .then(() => {
          navigate('/subscribers');
          messageApi.open({
            type: 'success',
            content: 'Subscriber successfully updated',
          });
        }
        ).catch((err) => {
          messageApi.open({
            type: 'error',
            content: err.response.data.message,
          });
        });
    } else {
      createSubscriber({
        ...form.getFieldsValue(),
        status: !isBlocked ? form.getFieldValue('status') : SubscriberStatus.FREE_TRIAL,
        validTill: !isBlocked ? form.getFieldValue('validTill') : undefined,
        isBlocked: isBlocked,
        webRestrictionType,
        mobileCount: webRestrictionType === LoginRestrictionTypeEnum.MAX_COUNT ? form.getFieldValue('mobileCount') : undefined,
        webCount:  webRestrictionType === LoginRestrictionTypeEnum.MAX_COUNT ? form.getFieldValue('webCount') : undefined,
        ipAddress: webRestrictionType === LoginRestrictionTypeEnum.IP_ADDRESS ? form.getFieldValue('ipAddress') : undefined,
        startDateOfPosts: postsCutOffDate?.startDateOfPosts || undefined,
        endDateOfPosts: postsCutOffDate?.endDateOfPosts || undefined
      })
        .then(() => {
          navigate('/subscribers');
          messageApi.open({
            type: 'success',
            content: 'Subscriber successfully created',
          });
        }
        ).catch((err) => {
          messageApi.open({
            type: 'error',
            content: err.response.data.message,
          });
        });
    }
  };

  useEffect(() => {
    if (form.getFieldValue('status') === SubscriberStatus.PAID) {
      setIsValidTillActive(true);
    } else {
      setIsValidTillActive(false);
    }
  }, [form.getFieldValue('status')]);

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle(subscriber?.name || 'Create Subscriber')}</title>
      </Helmet>
      {contextHolder}
      <HeaderBreadcrumb items={['Subscribers', 'Create/Edit Subscriber']} />
      <Card title={isEdit ? 'Edit Subscriber' : 'Create Subscriber'}>
        <Form name='Subscriber' form={form} onFinish={handleSubmit} layout='vertical'>
          <Row gutter={[24, 0]}>
            <Col xs={8}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: 'Please enter name' }, { min: 3, message: 'Name must be longer than 2 characters' }]}
                label='Name'
              >
                <Input placeholder="Name" size='large' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name='username'
                rules={[{ required: true, message: 'Please enter username' }, { min: 3, message: 'Username must be longer than 2 characters' }]}
                label='Username'
              >
                <Input placeholder="Username" size='large' autoComplete="off" />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name='email'
                rules={[{ required: true, message: 'Please enter email' }, { min: 3, message: 'Email must be longer than 2 characters' }]}
                label='Email'
              >
                <Input placeholder="Email" size='large' type='email' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name='phone'
                label='Phone'
              >
                <Input placeholder="Phone" size='large' type='phone' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name='company'
                label='Company'
              >
                <Input placeholder="Company" size='large' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name='country'
                label='Country'
              >
                <Select
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Select a country'
                  fieldNames={{ value: '_id', label: 'name' }}
                  options={countries?.sort()}
                  filterOption={(input: string, option: any) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name='unhashedPassword'
                rules={[{ required: !isEdit, message: 'Please enter a password' }, { min: 3, message: 'Password must be longer than 2 characters' }]}
                label={(isEdit ? 'Update ' : '') + 'Password'}
              >
                <Input type='password' placeholder="Password" size='large' autoComplete="off" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name='confirmUnhashedPassword'
                rules={[{ required: !isEdit, message: 'Please enter password again' }, { min: 3, message: 'Password must be longer than 2 characters' }]}
                label={(isEdit ? 'Update ' : '') + 'Password Again'}
              >
                <Input placeholder="Password again" size='large' type='password' />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name='webRestrictionType'
                label='Web Restriction Type'
              >
                <Select
                  size='large'
                  style={{ width: '100%' }}
                  placeholder="Select web restriction type"
                  options={[
                    {
                      value: LoginRestrictionTypeEnum.MAX_COUNT,
                      label: 'Max Count'
                    },
                    {
                      value: LoginRestrictionTypeEnum.IP_ADDRESS,
                      label: 'Ip Address'
                    }
                  ]}
                  onChange={(e) => setWebRestrictionType(e)}
                  filterOption={(input: string, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  allowClear
                />
              </Form.Item>
            </Col>
            {webRestrictionType === LoginRestrictionTypeEnum.MAX_COUNT ? (
              <>
                <Col xs={12}>
                  <Form.Item
                    name='webCount'
                    label='Web Max Count'
                  >
                    <InputNumber
                      placeholder="Web max count"
                      size="large"
                      style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name='mobileCount'
                    label='Mobile Max Count'
                  >
                    <InputNumber
                      placeholder="Mobile max count"
                      size="large"
                      style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </>
            ) : webRestrictionType === LoginRestrictionTypeEnum.IP_ADDRESS && (
              <Col xs={24}>
                <Form.Item
                  name='ipAddress'
                  label='Ip Addresses'
                  rules={[{ required: true, message: 'Please add at least 1 ip address' }]}
                >
                  <Select mode="tags" size="large" placeholder="Ip addresses (e.g. 10.02.20.401)" />
                </Form.Item>
              </Col>
            )}
            <Col xs={24}>
              <Form.Item
                name='commodities'
                rules={[{ required: true, message: 'Please select at least 1 commodity' }]}
                label='Commodities'
              >
                <Select
                  mode="multiple"
                  allowClear
                  size='large'
                  style={{ width: '100%' }}
                  placeholder="Select this relevant commodities"
                  fieldNames={{ value: '_id', label: 'name' }}
                  options={commodities?.sort()}
                  filterOption={(input: string, option: any) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label='Posts Cut off Date'
              >
                <DatePicker.RangePicker
                  size="large"
                  style={{width: '100%'}}
                  defaultValue={[postsCutOffDate.startDateOfPosts, postsCutOffDate.endDateOfPosts]}
                  onChange={(event) => setPostsCutOffDate({
                    startDateOfPosts: event?.[0],
                    endDateOfPosts: event?.[1]
                  })}
                />
              </Form.Item>
            </Col>
            <Col xs={8} style={{display: 'flex', alignItems:'center', gap:'8px'}}>
              <Form.Item
                name='webAccess'
                label='Web Access'
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  id="web-access"
                  defaultChecked={form.getFieldValue('webAccess')}
                  onChange={(e) => form.setFieldValue('webAccess', e)}
                />
                <label htmlFor='web-access' style={{marginLeft: '8px'}}>
                  <b>Web Access</b>
                </label>
              </Form.Item>
            </Col>
            <Col xs={8} style={{display: 'flex', alignItems:'center', gap:'8px'}}>
              <Form.Item
                name='mobileAccess'
                label='Mobile Access'
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  id="mobile-access"
                  defaultChecked={form.getFieldValue('mobileAccess')}
                  onChange={(e) => form.setFieldValue('mobileAccess', e)}
                />
                <label htmlFor='mobile-access' style={{marginLeft: '8px'}}>
                  <b>Mobile Access</b>
                </label>
              </Form.Item>
            </Col>
            <Col xs={8} style={{display: 'flex', alignItems:'center', gap:'8px'}}>
              <Form.Item
                name='apiAccess'
                label='API Access'
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  id="api-access"
                  defaultChecked={form.getFieldValue('apiAccess')}
                  onChange={(e) => form.setFieldValue('apiAccess', e)}
                />
                <label htmlFor='api-access' style={{marginLeft: '8px'}}>
                  <b>API Access</b>
                </label>
              </Form.Item>
            </Col>
            <Col xs={24} style={{display: 'flex', alignItems:'center', gap:'8px'}}>
              <Form.Item
                label='Blocked'
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  id="is-blocked"
                  defaultChecked={isBlocked}
                  onChange={(e: boolean) => setIsBlocked(e)}
                />
                <label htmlFor='is-blocked' style={{marginLeft: '8px'}}>
                  <b>{isBlocked ? 'Blocked' : 'Block'}</b>
                </label>
              </Form.Item>
            </Col>
            {!isBlocked && (
              <>
                <Col xs={isValidTillActive ? 12 : 24}>
                  <Form.Item
                    name='status'
                    rules={[
                      {
                        required: true,
                        message: 'Please select a status',
                      },
                    ]}
                    label='Status'
                  >
                    <Select
                      size='large'
                      style={{width: '100%'}}
                      placeholder='Select a status'
                      onChange={(e) => setIsValidTillActive(e === SubscriberStatus.PAID)}
                      options={[
                        {
                          value: SubscriberStatus.FREE_TRIAL,
                          label: 'Free Trial'
                        },
                        {
                          value: SubscriberStatus.PAID,
                          label: 'Paid'
                        }
                      ]}
                      filterOption={(input: string, option: any) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      showSearch
                      allowClear
                    />
                  </Form.Item>
                </Col>
                {isValidTillActive && (
                  <Col xs={12}>
                    <Form.Item
                      name='validTill'
                      rules={[{ required: true, message: 'Please select valid till' }]}
                      label='Valid Till'
                    >
                      <DatePicker size='large' style={{width: '100%'}} placeholder="Select valid till" />
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
            <Col xs={24} style={{marginTop: '-24px'}}>
              <Divider />
            </Col>
            <Col xs={24} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Button type="primary" shape="default" icon={<ArrowLeftOutlined />} size="large" danger style={{width: '200px'}} onClick={() => navigate(-1)}>
            Back
              </Button>
              <Button type="primary" htmlType='submit' shape="default" icon={isEdit ? <SaveOutlined /> : <PlusCircleOutlined />} size="large" style={{width: '200px'}}>
                {isEdit ? 'Save' : 'Create'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}
