import { AxiosResponse } from 'axios';
import service from './axiosRequest';
import storageService from './storageService';
import {AUTH_CHECK, LOGIN, LOGOUT, RESET_PASSWORD, UPDATE_PASSWORD} from '../core/consts/endpoints';
import { ACCESS_TOKEN } from '../core/consts/tokens';
import { LoginReq } from '../core/dtos/loginReq';
import { LoginRes } from '../core/dtos/loginRes';
import { UserInfoRes } from '../core/dtos/userInfoRes';
import {ForgotPasswordReq} from '../core/dtos/forgotPasswordReq';
import {ResetPasswordReq} from '../core/dtos/resetPasswordReq';

export function login(body: LoginReq): Promise<void> {
  return service.post(LOGIN, body)
    .then((res: AxiosResponse<LoginRes>) => {
      storageService.set(ACCESS_TOKEN, res.data.accessToken);
    })
    .catch((err) => { throw new Error(err.response.data.message); });
}

export function logout(): Promise<void> {
  return service.get(LOGOUT)
    .then(() => {
      storageService.remove(ACCESS_TOKEN);
    });
}

export function getUserInfo(): Promise<UserInfoRes> {
  return service.get<UserInfoRes>(AUTH_CHECK)
    .then((res: AxiosResponse<UserInfoRes>) => res.data);
}

export function forgotPassword(body: ForgotPasswordReq): Promise<void> {
  const config = {
    headers:{
      'api-key': process.env.REACT_APP_API_KEY
    }
  };
  return service.post(RESET_PASSWORD, body, config);
}

export function resetPassword(body: ResetPasswordReq, token: string): Promise<void> {
  const config = {
    headers:{
      'Authorization': token,
      'api-key': process.env.REACT_APP_API_KEY,
    }
  };
  return service.post(UPDATE_PASSWORD, body, config);
}

