import React, {Dispatch, SetStateAction} from 'react';
import {Button, Col, Form, Input, message, Modal, Row} from 'antd';
import {SaveOutlined} from '@ant-design/icons';
import {createSource, getAllSources} from '../../services/sourceService';
import {SourceRes} from '../../core/dtos/sourceRes';

export default function ModalCreateSource(
  {
    isOpen,
    setIsOpen,
    setSources
  }:
    {
        isOpen: boolean;
        setIsOpen: Dispatch<SetStateAction<boolean>>;
        setSources: Dispatch<SetStateAction<SourceRes[]>>;
    })
{
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    setIsOpen(false);

    createSource(form.getFieldsValue())
      .then(() => {
        getAllSources()
          .then((body: SourceRes[]) => {setSources(body);}
          );
        form.setFieldValue('name', '');
        setIsOpen(false);
        messageApi.open({
          type: 'success',
          content: 'Source successfully created',
        });
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        centered
        title="Create Source"
        open={isOpen}
        okText="Create"
        footer={
          <Button form="form" type="primary" htmlType='submit' shape="default" icon={<SaveOutlined />} size="large" style={{width: '120px'}}>
            Create
          </Button>
        }
        okButtonProps={{}}
        onOk={() => handleSubmit()}
        onCancel={() => setIsOpen(false)}
      >
        <Form id='form' name='Post' form={form} onFinish={handleSubmit} layout='vertical' style={{marginTop: '16px', marginBottom: '16px'}}>
          <Row gutter={[24, 0]}>
            <Col xs={24} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: 'Please enter source name' }, { min: 3, message: 'Source name must be longer than 2 characters' }]}
                label='Source Name'
                style={{width: '100%'}}
              >
                <Input placeholder="Source Name" size='large' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
