import './styles/normalize.scss';
import './styles/style.scss';
import 'react-quill/dist/quill.snow.css';
import './styles/editor-preview.scss';
import './styles/post-preview.scss';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import {AppContextProvider} from './context/siteContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#fed28a',
      },
    }}
  >
    <BrowserRouter>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </BrowserRouter>
  </ConfigProvider>
);
