import { Button, Col, Divider, Form, Input, message, Row, Select, Switch } from 'antd';
import Card from 'antd/es/card/Card';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import { getAllCommodities } from '../../../../services/commodityService';
import { CommodityRes } from '../../../../core/dtos/commodityRes';
import Loader from '../../../../components/Loader';
import { UserRes } from '../../../../core/dtos/userRes';
import { UserRole } from '../../../../core/enums/UserRole';
import { createUser, getUser, getUsers, updateUser } from '../../../../services/userService';
import { HttpRes } from '../../../../core/dtos/httpRes';
import createPageTitle from '../../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';
import {
  CheckOutlined,
  CloseOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

export default function User() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const [user, setUser] = useState<UserRes>({
    _id: '',
    createdAt: '',
    createdBy: {
      _id: '',
      name: ''
    },
    email: '',
    isAdmin: false,
    name: '',
    role: UserRole.AUTHOR,
    username: '',
    commodities: []
  });
  const [commodities, setCommodities] = useState<CommodityRes[]>([]);
  const isEdit = !!id;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRoleAuthor, setIsRoleAuthor] = useState<boolean>(false);
  const [users, setUsers] = useState<HttpRes<UserRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });

  useEffect(() => {
    Promise.all([
      getAllCommodities(),
      getUsers()
    ])
      .then(([commodities, users]: [CommodityRes[], HttpRes<UserRes>]) => {
        setCommodities(commodities);
        setUsers(users);
      });

    if (isEdit)
      getUser(id)
        .then((body: UserRes) => {
          form.setFieldsValue({
            ...body,
            editor: body.editor?._id,
            commodities: body.commodities.map((commodity) => commodity._id),
          });
          setUser(body);
          setIsRoleAuthor(body.role === UserRole.AUTHOR);
        })
        .finally(() => {
          setIsLoading(false);
        });
    else {
      setIsLoading(false);
      form.setFieldValue('isAdmin', false);
    }

  }, []);

  const handleSubmit = (e: any) => {
    if (isEdit) {
      updateUser({
        ...form.getFieldsValue(),
        _id: user._id
      })
        .then(() => {
          navigate('/users');
          messageApi.open({
            type: 'success',
            content: 'User successfully updated',
          });
        }
        ).catch((err) => {
          messageApi.open({
            type: 'error',
            content: err.response.data.message,
          });
        });
    } else {
      createUser(form.getFieldsValue())
        .then(() => {
          navigate('/users');
          messageApi.open({
            type: 'success',
            content: 'User successfully created',
          });
        }
        ).catch((err) => {
          messageApi.open({
            type: 'error',
            content: err.response.data.message,
          });
        });
    }

  };

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle(user?.name || 'Create User')}</title>
      </Helmet>
      {contextHolder}
      <HeaderBreadcrumb items={['Users', 'Create/Edit User']} />
      <Card title={isEdit ? 'Edit User' : 'Create User'}>
        <Form name='User' form={form} onFinish={handleSubmit} layout='vertical'>
          <Row gutter={[24, 0]}>
            <Col xs={isRoleAuthor ? 8 : 6}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: 'Please enter a name' }, { min: 3, message: 'Name must be longer than 2 characters' }]}
                label='Name'
              >
                <Input placeholder="Name" size='large' />
              </Form.Item>
            </Col>
            <Col xs={isRoleAuthor ? 8 : 6}>
              <Form.Item
                name='username'
                rules={[{ required: true, message: 'Please enter a username' }, { min: 3, message: 'Username must be longer than 2 characters' }]}
                label='Username'
              >
                <Input placeholder="Username" size='large' />
              </Form.Item>
            </Col>
            <Col xs={isRoleAuthor ? 8 : 6}>
              <Form.Item
                name='email'
                rules={[{ required: true, message: 'Please enter an email' }, { min: 3, message: 'Email must be longer than 2 characters' }]}
                label='Email'
              >
                <Input placeholder="Email" size='large' type='email' />
              </Form.Item>
            </Col>
            <Col xs={isRoleAuthor ? 12 : 6}>
              <Form.Item
                name='role'
                label='Role'
                rules={[{ required: true, message: 'Please select a role' }]}
              >
                <Select
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Select a role'
                  onChange={(e) => setIsRoleAuthor(e === UserRole.AUTHOR)}
                  options={[
                    {
                      value: UserRole.AUTHOR,
                      name: UserRole.AUTHOR
                    },
                    {
                      value: UserRole.EDITOR,
                      name: UserRole.EDITOR
                    }
                  ]}
                  filterOption={(input: string, option: any) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  allowClear
                />
              </Form.Item>
            </Col>
            {isRoleAuthor && (
              <Col xs={12}>
                <Form.Item
                  name='editor'
                  label='Editor'
                  rules={[{ required: true, message: 'Please select an editor' }]}
                >
                  <Select
                    size='large'
                    style={{ width: '100%' }}
                    placeholder="Select a editor"
                    fieldNames={{ value: '_id', label: 'name' }}
                    options={users.items?.sort()}
                    filterOption={(input: string, option: any) =>
                      (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    allowClear
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24}>
              <Form.Item
                name='commodities'
                label='Commodities'
                rules={[{ required: true, message: 'Please select at least 1 commodity' }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  size='large'
                  style={{ width: '100%' }}
                  placeholder="Select this relevant commodities"
                  fieldNames={{ value: '_id', label: 'name' }}
                  options={commodities?.sort()}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name='unhashedPassword'
                rules={[{ required: !isEdit, message: 'Please enter a password' }, { min: 3, message: 'Password must be longer than 2 characters' }]}
                label={(isEdit ? 'Update ' : '') + 'Password'}
              >
                <Input placeholder="Password" size='large' type='password' />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name='confirmUnhashedPassword'
                rules={[{ required: !isEdit, message: 'Please enter password again' }, { min: 3, message: 'Password must be longer than 2 characters' }]}
                label={(isEdit ? 'Update ' : '') + 'Password Again'}
              >
                <Input placeholder="Password Again" size='large' type='password' />
              </Form.Item>
            </Col>
            <Col xs={24} style={{display: 'flex', alignItems:'center', gap:'8px'}}>
              <Form.Item
                name='isAdmin'
                label='Admin'
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  id="is-admin"
                  defaultChecked={form.getFieldValue('isAdmin') || false}
                  onChange={(e) => form.setFieldValue('isAdmin', e)}
                />
                <label htmlFor='is-admin' style={{marginLeft: '8px'}}>
                  <b>Admin</b>
                </label>
              </Form.Item>
            </Col>
            <Col xs={24} style={{marginTop: '-24px'}}>
              <Divider />
            </Col>
            <Col xs={24} style={{gap: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Button type="primary" shape="default" icon={<ArrowLeftOutlined />} size="large" danger style={{width: '200px'}} onClick={() => navigate(-1)}>
            Back
              </Button>
              <Button type="primary" htmlType='submit' shape="default" icon={isEdit ? <SaveOutlined /> : <PlusCircleOutlined />} size="large" style={{width: '200px'}}>
                {isEdit ? 'Save' : 'Create'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}
