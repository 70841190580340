import {SubscriberRequestFilter} from '../../../../../core/types/SubscriberRequestFilter';
import {Col, Form, Input, Row, Select} from 'antd';
import {UserRole} from '../../../../../core/enums/UserRole';
import ModalFilterLayout from '../../../../../components/Modals/ModalFilterLayout';

export default function ModalUsersFilter({
  isOpen,
  setIsOpen,
  changeFilter,
  form
}: {
    isOpen: boolean;
    setIsOpen: React.Dispatch<boolean>
    changeFilter: (changedFilter: Partial<SubscriberRequestFilter>) => void,
    form: any
}) {
  return (
    <ModalFilterLayout header='Filter Users' isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => changeFilter(form.getFieldsValue())}>
      <Form name='User' form={form} layout='vertical'>
        <Row gutter={[18, 0]}>
          <Col xs={12} style={{marginTop: '16px'}}>
            <Form.Item
              name='name'
              label='Name'
            >
              <Input placeholder="Search in name" size='large' />
            </Form.Item>
          </Col>
          <Col xs={12} style={{marginTop: '16px'}}>
            <Form.Item
              name='username'
              label='Username'
            >
              <Input placeholder="Search in username" size='large' />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              name='email'
              label='Email'
            >
              <Input placeholder="Search in email" size='large' />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              name='role'
              label='Role'
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select a role"
                size='large'
                options={[
                  {
                    value: UserRole.AUTHOR,
                    label: UserRole.AUTHOR
                  },
                  {
                    value: UserRole.EDITOR,
                    label: UserRole.EDITOR
                  }
                ]}
                filterOption={(input: string, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              name='isAdmin'
              label='Admin'
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select admin status"
                size='large'
                options={[
                  {
                    label: 'Yes',
                    value: true
                  },
                  {
                    label: 'No',
                    value: false
                  }
                ]}
                filterOption={(input: string, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalFilterLayout>
  );
}
