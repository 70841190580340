import { AxiosResponse } from 'axios';
import { COUNTRY, COUNTRY_ALL } from '../core/consts/endpoints';
import { CountryReq } from '../core/dtos/countryReq';
import { CountryRes } from '../core/dtos/countryRes';
import { HttpRes } from '../core/dtos/httpRes';
import { RequestFilter } from '../core/types/RequestFilter';
import service from './axiosRequest';

export function getAllCountries(): Promise<CountryRes[]> {
  return service.get<CountryRes[]>(COUNTRY_ALL)
    .then((res: AxiosResponse<CountryRes[]>) => res.data);
}

export function getCountries(params: RequestFilter): Promise<HttpRes<CountryRes>> {
  return service.get<HttpRes<CountryRes>>(COUNTRY, { params })
    .then((res: AxiosResponse<HttpRes<CountryRes>>) => res.data);
}

export function createCountry(body: CountryReq) {
  return service.post(COUNTRY, body)
    .then((res: AxiosResponse<CountryReq>) => res.data);
}

export function updateCountry(body: CountryReq) {
  return service.patch(COUNTRY, body)
    .then((res: AxiosResponse<CountryReq>) => res.data);
}

export function deleteCountry(id: string) {
  return service.delete(`${COUNTRY}/${id}`);
}
