import { AxiosResponse } from 'axios';
import {NOTIFICATION_ALL, NOTIFICATION_CUSTOM} from '../core/consts/endpoints';
import service from './axiosRequest';
import {NotificationReq} from '../core/dtos/notificationReq';
import {NotificationCustomReq} from '../core/dtos/notificationCustomReq';

export function sendNotification(body: NotificationReq) {
  return service.post(NOTIFICATION_ALL, body)
    .then((res: AxiosResponse) => res.data);
}

export function sendCustomNotification(body: NotificationCustomReq) {
  return service.post(NOTIFICATION_CUSTOM, body)
    .then((res: AxiosResponse) => res.data);
}
