export const DASHBOARD='dashboard';
export const CONTACT='contact';
export const POSTS='posts';
export const BLOGS='blogs';
export const BLOG_COMMENTS='blog-comments';
export const SOURCES='sources';
export const SUBSCRIBERS='subscribers';
export const NOTIFICATIONS='notifications';
export const USERS='users';
export const CATEGORIES='categories';
export const COUNTRIES='countries';
export const COMMODITIES='commodities';
export const LOGIN='login';
export const FORGOT_PASSWORD='forgot-password';
export const RESET_PASSWORD='reset-password';
export const MAIL_GENERATOR='mail-generator';
