import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Card, Table, Button, Tooltip, Tag, Popconfirm, Form} from 'antd';
import Search from 'antd/es/transfer/search';
import { debounce } from 'lodash';
import { HttpRes } from '../../../../core/dtos/httpRes';
import HeaderBreadcrumb from '../../../../components/Breadcrumb';
import formatDate from '../../../../utils/formatDate';
import Loader from '../../../../components/Loader';
import Pagination from 'antd/lib/pagination';
import { UserRole } from '../../../../core/enums/UserRole';
import { UserRes } from '../../../../core/dtos/userRes';
import { deleteUser, getUsers } from '../../../../services/userService';
import { UserRequestFilter } from '../../../../core/types/UserRequestFilter';
import createPageTitle from '../../../../utils/createPageTitle';
import {Helmet} from 'react-helmet';
import ModalFilter from './components/ModalFilter';
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  FilterOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';

const generateFilterParams = (filter: UserRequestFilter): UserRequestFilter => ({
  limit: filter.limit || undefined,
  page: filter.page || undefined,
  name: filter.name || undefined,
  username: filter.username || undefined,
  email: filter.email || undefined,
  role: filter.role || undefined,
  isAdmin: filter.isAdmin,
});

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Admin',
    dataIndex: 'admin',
    key: 'admin',
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    key: 'createdBy',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];

function Users() {
  const [users, setUsers] = useState<HttpRes<UserRes>>({
    items: [],
    pagination: {
      count: 0,
      current: 0,
      hasNext: false,
      hasPrev: false,
      itemCount: 0,
      next: null,
      prev: null
    },
    totalItemCount: 0
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<UserRequestFilter>({
    page: 1,
    limit: 20,
  });
  const [isSearchModalOpened, setIsSearchModalOpened] = useState<boolean>(false);
  const [form] = Form.useForm();

  const mapUsers = (users: UserRes[]) => users.map(user => (
    {
      key: user._id,
      name: (
        <Link to={user._id}>{user.name}</Link>
      ),
      email: (
        <span>{user.email}</span>
      ),
      role: <Tag color={user.role === UserRole.AUTHOR ? 'default' : 'green'}>{user.role}</Tag>,
      admin: (
        <span>{user.isAdmin}</span>
      ),
      createdBy: (
        <span>{user.createdBy?.name}</span>
      ),
      createdAt: (
        <span>{formatDate(user.createdAt)}</span>
      ),
      actions: (
        <Row className="ant-employed table-actions" gutter={[8, 0]}>
          <Col>
            <Tooltip title="Edit">
              <Link to={user._id}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              placement="bottomRight"
              title='Delete the User'
              description='Are you sure to delete this user?'
              onConfirm={() => {
                deleteUser(user._id)
                  .then(() => {
                    handleGetUsers();
                  });
              }}
              okText="Delete"
              cancelText="Cancel"
            >
              <Tooltip title="Delete User">
                <Button
                  danger
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    }
  ));

  const handleGetUsers = () => {
    const filterParams: UserRequestFilter = generateFilterParams(filter);

    getUsers(filterParams)
      .then((body: HttpRes<UserRes>) => setUsers(body))
      .finally(() => setIsLoading(false));

  };

  useEffect(() => {
    handleGetUsers();
  }, [filter]);

  const changeFilter = (changedFilter: Partial<UserRequestFilter>) => {
    setFilter((filter) => ({
      ...filter,
      ...changedFilter
    }));
  };

  const handleSearchWithDebounce = useMemo(() => {
    return debounce(
      (e) => changeFilter({ name: e.target.value }),
      500
    );
  }, []);

  const refreshFilter = () => {
    form.resetFields();
    changeFilter({
      page: 1,
      name: undefined,
      username: undefined,
      email: undefined,
      role: undefined,
      isAdmin: undefined,
    });
  };

  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Helmet>
        <title>{createPageTitle('Users')}</title>
        <meta/>
      </Helmet>
      <div className='breadcrumb-wrapper'>
        <HeaderBreadcrumb items={['Users']} />
        <Row gutter={[12, 0]}>
          <Col>
            <Button
              type="default"
              shape="default"
              size="large"
              onClick={() => setIsSearchModalOpened(true)}
              icon={<FilterOutlined />}>
              Advanced Filter
            </Button>
            {filter && (
              <Popconfirm
                placement="bottomRight"
                title='Refresh Filtering'
                description='Are you sure to refresh filtering?'
                onConfirm={refreshFilter}
                okText="Refresh"
                cancelText="Cancel"
              >
                <Tooltip title="Refresh filter">
                  <Button type="primary" icon={<CloseCircleOutlined />} className="reset-filter-button" />
                </Tooltip>
              </Popconfirm>
            )}
          </Col>
          <Col>
            <Link to='create'>
              <Button
                type="primary"
                shape="default"
                size="large"
                style={{width: '120px'}}
                icon={<PlusCircleOutlined />}>
            Create
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="List of Users"
              extra={
                <Row className='table-filter'>
                  <Col xs={12} style={{marginRight: '10px'}}>
                    <Search
                      placeholder="Search"
                      onChange={handleSearchWithDebounce} />
                  </Col>
                </Row>
              }>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={mapUsers(users.items)}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <ModalFilter isOpen={isSearchModalOpened} setIsOpen={setIsSearchModalOpened} changeFilter={changeFilter} form={form} />
      <Card style={{marginTop: '16px', justifyContent: 'center', display:'flex'}}>
        <Pagination total={users.totalItemCount}
          current={filter.page}
          onChange={(e) => changeFilter({ page: e })}
          pageSize={filter.limit}
          showTotal={(total) => `Total ${total} items`}
          onShowSizeChange={(e, selectedNumber) => {changeFilter({ limit: selectedNumber});}}
          defaultPageSize={20}
          showSizeChanger />
      </Card>
    </>
  );
}

export default Users;
